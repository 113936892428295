import {
  Scene,
  AmbientLight,
  DirectionalLight,
  PlaneGeometry,
  ShadowMaterial,
  Mesh,
  DirectionalLightHelper,
  PointLight,
  PointLightHelper,
  RectAreaLight,
} from 'three';

import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper.js';
import { RectAreaLightUniformsLib } from 'three/examples/jsm/lights/RectAreaLightUniformsLib.js';

export const addLights = (scene: Scene, lights: any): void => {
  RectAreaLightUniformsLib.init();
  // const ambientLight = new AmbientLight(0xffffff, 0.7);
  // scene.add(ambientLight);

  // const pointLight = new PointLight(0xba9575, 0.3);
  // scene.add(pointLight);

  lights.forEach((light) => {
    switch (light.type) {
      case 'ambient':
        const ambientLight = new AmbientLight(
          light.colour || 0xffffff,
          light.intensity || 1
        );
        scene.add(ambientLight);
        break;
      case 'point':
        const pointLight = new PointLight(
          light.colour || 0xffffff,
          light.intensity || 1,
          0,
          2
        );
        scene.add(pointLight);
        if (light.helper) {
          const helper = new PointLightHelper(pointLight, 10);
          scene.add(helper);
        }
        break;
      case 'directional':
        const dirLight = new DirectionalLight(
          light.colour || 0xffffff,
          light.intensity || 1
        );
        dirLight.shadow.camera.left = -50;
        dirLight.shadow.camera.right = 50;
        dirLight.shadow.camera.top = 50;
        dirLight.shadow.camera.bottom = -50;
        dirLight.position.set(
          light.position.x,
          light.position.y,
          light.position.z
        );
        dirLight.castShadow = true;
        dirLight.shadow.mapSize.width = 1024;
        dirLight.shadow.mapSize.height = 1024;
        dirLight.shadow.camera.far = 100;
        scene.add(dirLight);

        dirLight.target.position.set(
          light.target.x,
          light.target.y,
          light.target.z
        );
        scene.add(dirLight.target);

        if (light.helper) {
          const helper = new DirectionalLightHelper(dirLight, 5);
          scene.add(helper);
        }
        break;
      case 'rectangular':
        const rectLight = new RectAreaLight(
          light.colour || 0xffffff,
          light.intensity || 1,
          50,
          10
        );
        rectLight.position.set(
          light.position.x,
          light.position.y,
          light.position.z
        );
        rectLight.lookAt(light.target.x, light.target.y, light.target.z);
        scene.add(rectLight);
        if (light.helper) {
          const rectLightHelper = new RectAreaLightHelper(rectLight);
          rectLight.add(rectLightHelper);
        }
        break;
      default:
        break;
    }
  });

  const floor = new Mesh(
    new PlaneGeometry(150, 150),
    new ShadowMaterial({ opacity: 0.3 })
  );
  floor.rotation.x = -Math.PI / 2;
  floor.position.set(0, -12, 0);
  floor.name = 'floor';
  floor.receiveShadow = true;
  scene.add(floor);
};
