import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectAssetList,
  selectLoadedAssets,
  selectSlowConnection,
} from '@store/tour';
import {
  PreloaderContainer,
  InnerContainer,
  LoadingSpinner,
  Progress,
  Text,
} from './Preloader.styled';
import { preloadAssets } from '@functions/preloadAssets';
import { useParams } from 'react-router';
import { updateCurrentTour } from '@functions/updateCurrentTour';

interface Props {
  setAssetsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Preloader = ({ setAssetsLoaded }: Props) => {
  const assetList = useSelector(selectAssetList);
  const loadedAssets = useSelector(selectLoadedAssets);
  const slowConnection = useSelector(selectSlowConnection);
  // const { tour: tourID } = useParams<{ tour: string }>();

  const [loadedPercentage, setLoadedPercentage] = React.useState(0);

  // React.useEffect(() => {
  //   updateCurrentTour(tourID);
  // }, []);

  const skipLoading = false;
  // const skipLoading = process.env.NODE_ENV === 'development' || false;

  React.useEffect(() => {
    if (skipLoading) setAssetsLoaded(true);
    else if (assetList.length !== 0) {
      preloadAssets(assetList);
    }
  }, [assetList]);

  React.useEffect(() => {
    if (assetList.length !== 0) {
      setLoadedPercentage((loadedAssets.length / assetList.length) * 100);
    }
  }, [loadedAssets]);

  React.useEffect(() => {
    if (loadedPercentage === 100) setAssetsLoaded(true);
  }, [loadedPercentage]);

  return (
    <PreloaderContainer>
      <InnerContainer>
        <LoadingSpinner />
        <Progress>{loadedPercentage.toFixed()}%</Progress>
        <Text>Loading tour assets</Text>
        {slowConnection ? (
          <Text small>Lower connection speed - optimised assets</Text>
        ) : (
          <Text small>High connection speed - full assets</Text>
        )}
      </InnerContainer>
    </PreloaderContainer>
  );
};
