import React from 'react';
import { useSelector } from 'react-redux';

import { selectBackgroundAudioMuted } from '@store/tour';

const fadeInterval = 0.02;

interface Props {
  currentAudio: string;
  initialVolume: number;
}
export const Audio = ({ currentAudio, initialVolume }: Props) => {
  const muted = useSelector(selectBackgroundAudioMuted);
  const [volumeChanging, setVolumeChanging] = React.useState(false);

  const audioRef = React.useRef<HTMLAudioElement>(null);

  const fadeToVolume = (newVolume) => {
    return new Promise((resolve, reject) => {
      if (volumeChanging || newVolume === undefined) {
        reject();
        return;
      }
      setVolumeChanging(true);
      const audioEl = audioRef.current;
      if (!audioEl) {
        reject();
        return;
      }
      const currentVolume = audioEl.volume;
      if (currentVolume === newVolume) resolve('resolved');
      if (newVolume > currentVolume) {
        // Increase volume
        const fadeUpInterval = setInterval(() => {
          if (audioEl.volume + fadeInterval > newVolume) {
            clearInterval(fadeUpInterval);
            setVolumeChanging(false);
            resolve('resolved');
          } else
            audioEl.volume = +Number(audioEl.volume + fadeInterval).toFixed(2);
        }, 10);
      } else {
        // Decrease volume
        const fadeUpInterval = setInterval(() => {
          if (audioEl.volume - fadeInterval < newVolume) {
            setVolumeChanging(false);
            clearInterval(fadeUpInterval);
            resolve('resolved');
          } else
            audioEl.volume = +Number(audioEl.volume - fadeInterval).toFixed(2);
        }, 10);
      }
    });
  };

  const fadeOut = () => {
    const audioEl = audioRef.current;
    if (audioEl && audioEl.volume !== 0) {
      fadeToVolume(0);
    }
  };

  const fadeIn = () => {
    const audioEl = audioRef.current;
    if (audioEl && audioEl.volume !== 1) {
      fadeToVolume(1);
    }
  };

  const playPause = () => {
    const audioEl = audioRef.current;
    if (!audioEl) return;
    if (audioEl.paused) {
      audioEl.play();
      fadeIn();
    } else {
      fadeOut();
      audioEl.pause();
    }
  };

  const changeVolume = (e) => {
    fadeToVolume(e.detail.newVolume);
  };

  React.useEffect(() => {
    const audioEl = audioRef.current;
    if (audioEl) {
      audioEl.volume = 0;
    }
    window.addEventListener('fade-in-audio', fadeIn);
    window.addEventListener('fade-out-audio', fadeOut);
    window.addEventListener('toggle-audio', playPause);
    window.addEventListener('transition-volume-audio', changeVolume);

    return () => {
      window.removeEventListener('fade-in-audio', fadeIn);
      window.removeEventListener('fade-out-audio', fadeOut);
      window.removeEventListener('toggle-audio', playPause);
      window.removeEventListener('transition-volume-audio', changeVolume);
    };
  }, []);

  React.useEffect(() => {
    const audioEl = audioRef.current;
    if (!audioEl) return;
    if (muted) {
      audioEl.muted = true;
    } else audioEl.muted = false;
  }, [muted]);

  React.useEffect(() => {
    const audioEl = audioRef.current;
    if (!audioEl) return;
    const audioPath = `/audio/${currentAudio}`;
    if (currentAudio && audioPath !== audioEl.src) {
      fadeToVolume(0).then(() => {
        audioEl.src = audioPath;
        audioEl.load();
        audioEl.play();
        fadeToVolume(initialVolume);
      });
    }
  }, [currentAudio, initialVolume]);

  return <audio ref={audioRef} id="audio" autoPlay loop muted={muted}></audio>;
};
