import React from 'react';
import {
  CardContainer,
  Image,
  Title,
  Text,
  TextContainer,
  ImageContainer,
} from './Card.styled';

interface Props {
  image: string;
  title: string;
  text?: string;
}

export const Card = ({ image, title, text }: Props) => {
  return (
    <CardContainer>
      <ImageContainer>
        <Image src={image} alt="" />
      </ImageContainer>
      <TextContainer>
        <Title>{title}</Title>
        {text && <Text>{text}</Text>}
      </TextContainer>
    </CardContainer>
  );
};
