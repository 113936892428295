import React from 'react';
import { openFullscreen } from '@functions/helpers';
import {
  OuterContainer,
  InnerContainer,
  StartButton,
  IntroText,
  FullScreenWarning,
  Title,
} from './Onboarding.styled';

import { useSelector } from 'react-redux';
import {
  selectOnboardingText,
  selectTourName,
  selectTourStartRoom,
} from '@store/tour';
import { useRouteMatch } from 'react-router-dom';

export const Onboarding = () => {
  const startRoom = useSelector(selectTourStartRoom);
  const onboardingText = useSelector(selectOnboardingText);
  const tourTitle = useSelector(selectTourName);

  const matches = useRouteMatch();
  let url = '';
  if (matches) url = matches.url;
  return (
    <OuterContainer>
      <InnerContainer>
        <IntroText>
          <Title>{tourTitle}</Title>
          {onboardingText && (
            <div dangerouslySetInnerHTML={{ __html: onboardingText }}></div>
          )}
        </IntroText>
        <StartButton
          to={`${url}/${startRoom}`}
          onClick={() => openFullscreen(document.body)}
        >
          Start
        </StartButton>
        <FullScreenWarning>
          <p>The tour will launch in full screen.</p>
        </FullScreenWarning>
      </InnerContainer>
    </OuterContainer>
  );
};
