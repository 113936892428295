import { XYZPosition } from '@store/rooms';
import { Group, Scene } from 'three';

export const add3DModelToScene = (
  modelContainer: Group,
  position: XYZPosition,
  lookAt: XYZPosition,
  scale = 1,
  rotate = false,
  scene: Scene
): void => {
  modelContainer.scale.set(scale, scale, scale);
  modelContainer.position.set(position.x, position.y, position.z);
  modelContainer.lookAt(lookAt.x, lookAt.y, lookAt.z);
  scene.add(modelContainer);

  if (rotate) {
    setInterval(() => {
      if (window.sceneReady) {
        modelContainer.rotateY(0.001);
        window.viewer.needsUpdate();
      }
    }, 1);
  }
};
