import React from 'react';
import {
  MapContainer,
  CloseButton,
  MapView,
  Background,
  Marker,
  RoomList,
  RoomLabel,
  Title,
  MapChangeButton,
  Description,
  DownloadButton,
  OverviewLink,
  Inner,
  AreaMarker,
} from './Map.styled';

import { useSelector } from 'react-redux';
import {
  selectCurrentRoom,
  selectMapInformation,
  selectTourName,
  selectVisistedRooms,
} from '@store/tour';
import { useRouteMatch } from 'react-router-dom';

interface Props {
  show: boolean;
  setShowMap: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Map = ({ show, setShowMap }: Props) => {
  const mapInfo = useSelector(selectMapInformation);
  const currentRoom = useSelector(selectCurrentRoom);
  const tourName = useSelector(selectTourName);
  const visitedRooms = useSelector(selectVisistedRooms);
  const [showOverview, setShowOverview] = React.useState(mapInfo.showOverview);
  const [areaIndex, setAreaIndex] = React.useState(0);

  const matches = useRouteMatch();
  let baseUrl = '';
  if (matches) baseUrl = matches.url.split('/')[1];

  const currentArea = mapInfo.areas[areaIndex];

  // const pulseMarker = (roomID) => {
  //   const marker = document.querySelector(
  //     `[data-type=room-marker][data-room=${roomID}]`
  //   );
  //   if (!marker) return;
  //   marker.classList.toggle('pulse');
  // };

  React.useEffect(() => {
    if (!currentRoom) return;
    const { areas } = mapInfo;
    let newAreaIndex = 0;
    areas.forEach((area, index) => {
      area.rooms.forEach((room) => {
        if (room.id === currentRoom.id) newAreaIndex = index;
      });
    });
    if (showOverview) {
      setAreaIndex(newAreaIndex);
    }
  }, [currentRoom, showOverview, show]);

  React.useEffect(() => {
    const labels = document.querySelectorAll('[data-type=room-label]');
    if (labels.length) {
      labels.forEach((label) => {
        if (label instanceof HTMLElement) {
          label.addEventListener('mouseenter', () => {
            const marker = document.querySelector(
              `[data-type="room-marker"][data-room="${label.dataset.room}"]`
            );
            if (!marker) return;
            marker.classList.add('pulse');
          });
          label.addEventListener('mouseleave', () => {
            const marker = document.querySelector(
              `[data-type="room-marker"][data-room="${label.dataset.room}"]`
            );
            if (!marker) return;
            marker.classList.remove('pulse');
          });
        }
      });
    }

    const map = document.getElementById('map');
    const handleOutsideMapClick = (e) => {
      if (e.target === map) {
        setShowMap(false);
      }
    };

    if (map) {
      map.addEventListener('click', handleOutsideMapClick);
    }
  }, []);

  return (
    <MapContainer id="map" show={show}>
      <Inner id="map-inner">
        <CloseButton
          onClick={() => {
            setShowMap(false);
            setShowOverview(mapInfo.showOverview);
          }}
        />
        {!showOverview && mapInfo.showOverview && (
          <OverviewLink onClick={() => setShowOverview(true)}>
            Overview
          </OverviewLink>
        )}

        <Title overview={showOverview}>
          {showOverview ? tourName : currentArea.title}
        </Title>

        {currentArea.description && (
          <Description>{currentArea.description}</Description>
        )}

        {!showOverview && mapInfo.areas.length > 1 && (
          <MapChangeButton
            onClick={() =>
              setAreaIndex(
                areaIndex + 1 < mapInfo.areas.length ? areaIndex + 1 : 0
              )
            }
          >
            {
              mapInfo.areas[
                areaIndex + 1 < mapInfo.areas.length ? areaIndex + 1 : 0 // next area's title
              ].title
            }
          </MapChangeButton>
        )}

        {showOverview && (
          <MapView roomsHidden={mapInfo.hideRoomSelect || false}>
            <Background src="/img/maps/house-front.png" alt="" />
            {mapInfo.areas.map((area, index) => {
              let isCurrentArea = false;
              console.log({ a: currentArea.title, b: area.title });
              if (currentArea.title === area.title) isCurrentArea = true;
              return (
                <AreaMarker
                  key={`area-marker-${index}`}
                  currentArea={isCurrentArea}
                  onClick={() => {
                    setShowOverview(false);
                    setAreaIndex(index);
                  }}
                  style={{
                    top: `${
                      area.overviewMarkerPosition
                        ? area.overviewMarkerPosition.y
                        : 0
                    }%`,
                    left: `${
                      area.overviewMarkerPosition
                        ? area.overviewMarkerPosition.x
                        : 0
                    }%`,
                  }}
                >
                  {area.title}
                </AreaMarker>
              );
            })}
          </MapView>
        )}

        {!showOverview && mapInfo.showVisualMap && (
          <MapView roomsHidden={mapInfo.hideRoomSelect || false}>
            <Background src={`/img/maps/${currentArea.background}`} alt="" />
            {currentArea.rooms.map((room, index) => {
              let isCurrentRoom = false;
              if (currentRoom && currentRoom.id.includes(room.id))
                isCurrentRoom = true;

              let isVisitedRoom = false;
              if (visitedRooms.includes(room.id)) isVisitedRoom = true;
              return (
                <Marker
                  data-type="room-marker"
                  data-room={room.id}
                  key={`room-marker-${index}`}
                  className={`${isCurrentRoom ? 'you-are-here' : ''} ${
                    mapInfo.hideMarkerLabels ? 'hide-labels' : ''
                  }`}
                  to={`/${baseUrl}/${room.id}`}
                  style={{
                    top: `${room.position[1]}%`,
                    left: `${room.position[0]}%`,
                  }}
                  disabled={mapInfo.onlyShowVisitedRooms && !isVisitedRoom}
                >
                  {isCurrentRoom ? (
                    <img src="/img/ui/marker-pin.png" alt="Current room" />
                  ) : (
                    <img
                      src={`/img/ui/circle-${
                        mapInfo.onlyShowVisitedRooms && !isVisitedRoom
                          ? 'grey'
                          : 'blue'
                      }.svg`}
                      alt=""
                    />
                  )}
                </Marker>
              );
            })}
          </MapView>
        )}

        {mapInfo.downloadFile && !showOverview && (
          <DownloadButton
            href={`/${mapInfo.downloadFile}`}
            download="ww-floor-plan.pdf"
          >
            Download floorplan
          </DownloadButton>
        )}
        {!showOverview && currentArea.rooms && !mapInfo.hideRoomSelect && (
          <RoomList>
            <h2 className="title">ROOMS</h2>
            <div className="label-container">
              {currentArea.rooms.map((room, index) => {
                let isCurrentRoom = false;
                if (currentRoom && currentRoom.id.includes(room.id))
                  isCurrentRoom = true;

                let isVisitedRoom = false;
                if (visitedRooms.includes(room.id)) isVisitedRoom = true;

                return (
                  <RoomLabel
                    data-type="room-label"
                    data-room={room.id}
                    key={`room-label-${index + 1}`}
                    to={`/${baseUrl}/${room.id}`}
                    $isCurrentRoom={isCurrentRoom} // transient attribute
                    disabled={mapInfo.onlyShowVisitedRooms && !isVisitedRoom}
                  >
                    {isCurrentRoom && (
                      <img
                        src="/img/ui/marker-pin.png"
                        alt=""
                        className="you-are-here-icon"
                      />
                    )}
                    <span>{index + 1}:</span>
                    <span>{room.name || room.id}</span>
                  </RoomLabel>
                );
              })}
            </div>
          </RoomList>
        )}
      </Inner>
    </MapContainer>
  );
};
