import { colours, fonts, notchedEdges } from '@styles';
import styled from 'styled-components';

export const HelpOverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
`;

export const TextContainer = styled.div`
  display: none;
  width: 350px;
  padding: 15px 20px;
  background: ${colours.white};
  ${notchedEdges('10px')}

  &.show {
    display: block;
  }

  @media (min-width: 992px) {
    padding: 25px 40px;
  }

  h1 {
    position: relative;
    margin: 0 0 20px;
    padding-bottom: 10px;
    font-family: ${fonts.argestaHeadline};
    font-size: 22px;
    text-transform: uppercase;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: ${colours.black};
    }

    @media (min-width: 992px) {
      font-size: 30px;
    }
  }

  p {
    margin: 0;
    font-family: ${fonts.poppins};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;

    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  img {
    display: inline-block;
    height: 10px;
    padding: 0 10px;
    vertical-align: middle;

    @media (min-width: 992px) {
      height: 20px;
    }
  }

  ul {
    padding: 0;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    list-style: none;

    @media (min-width: 992px) {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
        .icon {
          background-color: ${colours.white};
        }
      }
    }

    .icon {
      display: block;
      width: 35px;
      height: 35px;
      background-color: ${colours.black};
      border-radius: 50%;
      background-size: 85%;
      background-position: center;
      background-repeat: no-repeat;

      @media (min-width: 992px) {
        width: 50px;
        height: 50px;
      }
    }

    p {
      width: 80%;
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 30px;
  width: 60px;
  height: 60px;
  padding: 20px;
  background-color: ${colours.white};
  background-repeat: no-repeat;
  background-image: url('/img/ui/icons/icon-close-gold.svg');
  background-position: center;
  background-size: contain;
  font-family: ${fonts.argestaText};
  font-size: 18px;
  text-transform: uppercase;
  transition: background 0.3s ease;
  ${notchedEdges('10px')}

  span {
    display: none;
  }

  &:hover {
    cursor: pointer;
    background-color: ${colours.grey};
  }

  @media (min-width: 992px) {
    width: 350px;
    background-position: left;
    background-size: 75px;

    span {
      display: block;
    }
  }
`;

export const CarouselButton = styled.button`
  position: absolute;
  top: calc(50vh - 35px);
  width: 70px;
  height: 70px;
  padding: 20px;
  background-color: ${colours.white};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-image: url('/img/ui/arrow.svg');
  transition: background 0.3s ease;
  ${notchedEdges('10px')}

  &:hover {
    cursor: pointer;
    background-color: ${colours.grey};
  }
`;

export const HighlightOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const NextButton = styled.button`
  margin: 20px 0 0;
  padding: 10px 20px;
  font-family: 'Argesta Text';
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  color: ${colours.black};
  text-decoration: none;
  border: 1px solid black;
  transition: background 0.5s ease;
  background-color: ${colours.white};

  &:hover {
    background-color: ${colours.grey};
    cursor: pointer;
  }
`;
