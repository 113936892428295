import { selectMapInformation } from '@store/tour';
import React from 'react';
import { useSelector } from 'react-redux';
import ToggleButton from '../ToggleButton';
import { MapToggleContainer } from './MapToggle.styled';

interface Props {
  setShowMap: React.Dispatch<React.SetStateAction<boolean>>;
  mapOpen: boolean;
  uiStyle: string;
}

export const MapToggle = ({ setShowMap, mapOpen, uiStyle }: Props) => {
  const mapInfo = useSelector(selectMapInformation);
  const textColour = uiStyle === 'hidden' ? '#fff' : '#000';

  const toggleMap = () => {
    if (mapOpen) setShowMap(false);
    else setShowMap(true);
  };

  return (
    // <MapButton
    //   onClick={() => {
    //     setShowMap(true);
    //   }}
    //   tabIndex={0}
    //   id="map-button"
    //   uiStyle={uiStyle}
    // >
    //   <MapButtonText uiStyle={uiStyle}>{mapInfo.label || 'Map'}</MapButtonText>
    //   <MapButtonIcon src={} />
    // </MapButton>

    <MapToggleContainer id="map-button">
      <ToggleButton
        active={!mapOpen}
        text={{
          inactive: 'hide',
          active: mapInfo.label || 'Map',
          colour: textColour,
        }}
        icon={`/img/ui/icons/icon-map-${uiStyle}.svg`}
        background={`url('/img/ui/backgrounds/map-toggle-background-${uiStyle}.png')`}
        onClick={toggleMap}
      />
    </MapToggleContainer>
  );
};
