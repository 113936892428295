import Card from '@components/OnboardingComponents/Card';
import LandingVideo from '@components/OnboardingComponents/LandingVideo';
import PasswordVerification from '@components/PasswordVerification';
import Header from '@layouts/Header';
import { colours } from '@styles';
import React from 'react';
import ReactGA from 'react-ga';

import {
  Cards,
  Content,
  PasswordContainer,
  Heading,
  TourPasswordContainer,
  Text,
} from './TourPassword.styled';

export const TourPassword = () => {
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  });

  return (
    <TourPasswordContainer>
      <Header
        backgroundColour={colours.white}
        decorativeBottomBorder={false}
        largetitle="Filming Locations"
      />

      <PasswordContainer id="email-container">
        <Text>
          If you’ve been provided with an access code, please enter it below to
          explore the locations.
        </Text>
        <PasswordVerification />
      </PasswordContainer>

      <Content>
        <LandingVideo title="Create a Grand Experience" />
        <Heading>Some tips before you start</Heading>
        <Text>
          Get the most out of your experience by following this handy advice
        </Text>
      </Content>

      <Cards>
        <Card
          image="/img/onboarding/information_popup.png"
          title="Information"
          text="Each room contains an information popup which contains dimensions and details of the room to decide if its right for you."
        />
        <Card
          image="/img/onboarding/WW_Onboard_Image2.png"
          title="High quality."
          text="We've designed this to show off the sumptuous house in all it's glory. It's best experienced on desktop and tablet devices over wifi."
        />
        <Card
          image="/img/onboarding/WW_Onboard_Image3.png"
          title="Highlights of the locations"
          text="This tour allows you to see the main filming locations available at Wentworth. Should you need something more, please ask us!"
        />
      </Cards>
    </TourPasswordContainer>
  );
};
