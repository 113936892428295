import { colours, fonts } from '@styles';
import styled from 'styled-components';

export const LandingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-image: url('/img/onboarding/background-pattern.svg');
`;

export const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  background-color: ${colours.white};

  &::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 100%;
    height: 15px;
    background-image: url('/img/decorative-border-bottom-white.svg');
    z-index: 1;
  }
`;

export const Heading = styled.h2`
  font-family: ${fonts.poppins};
  font-size: 20px;
  font-weight: 500;
  color: ${colours.themePrimary};
`;

export const Text = styled.p`
  font-family: ${fonts.poppins};
  font-size: 16px;
  color: ${colours.darkGrey};
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  padding-bottom: 150px;
  background-color: ${colours.white};
`;

export const Cards = styled.div`
  display: flex;
  justify-content: space-evenly;
  position: relative;
  width: 100%;
  max-width: 1000px;
  top: -100px;
`;
