import React from 'react';
import { FullscreenVideoEl, PlayButton } from './FullscreenVideo.styled';

import { useSelector } from 'react-redux';
import { useAppDispatch } from '@store/store';
import {
  selectFullscreenVideoFilename,
  selectFullscreenVideoSource,
  setFullscreenVideoFilename,
  setFullscreenVideoSource,
} from '@store/rooms';
import { selectShowCaptions } from '@store/tour';

export const FullscreenVideo = () => {
  const dispatch = useAppDispatch();
  const videoSrc = useSelector(selectFullscreenVideoSource);
  const filename = useSelector(selectFullscreenVideoFilename);
  const showCaptions = useSelector(selectShowCaptions);
  const fullscreenVideoRef = React.useRef<HTMLVideoElement>(null);
  const playButtonRef = React.useRef<HTMLButtonElement>(null);
  const [listenersAdded, setListenersAdded] = React.useState(false);

  React.useEffect(() => {
    if (
      fullscreenVideoRef &&
      fullscreenVideoRef.current instanceof HTMLVideoElement
    ) {
      if (!listenersAdded) {
        fullscreenVideoRef.current.addEventListener('click', function play() {
          fullscreenVideoRef.current?.play();
        });

        setListenersAdded(true);
      }
    }
  }, [fullscreenVideoRef.current]);

  React.useEffect(() => {
    if (
      videoSrc !== '' &&
      fullscreenVideoRef.current instanceof HTMLVideoElement
    ) {
      fullscreenVideoRef.current.play().catch(() => {
        console.log('Could not autoplay video');
        if (playButtonRef.current) playButtonRef.current.classList.add('show');
      });

      const endHandler = () => {
        console.log('fullscreen video ended');
        fullscreenVideoRef.current?.removeEventListener('ended', endHandler);
        dispatch(setFullscreenVideoSource(''));
        dispatch(setFullscreenVideoFilename(''));
        window.viewer.needsUpdate();
        window.dispatchEvent(new Event('next-event'));
        window.sceneReady = true;
        clearTimeout(window.fullscreenTimeout);
        const roomEl = document.getElementById('room');
        if (roomEl) {
          roomEl.classList.remove('cover-black');
        }
      };

      fullscreenVideoRef.current.addEventListener('ended', endHandler);
    }
  }, [videoSrc]);

  const playVideo = (e) => {
    const videoEl = fullscreenVideoRef.current;
    if (videoEl) {
      videoEl.play();
      e.target.classList.remove('show');
    }
  };
  return (
    <React.Fragment>
      <PlayButton
        id="fullscreen-video-play"
        onClick={playVideo}
        ref={playButtonRef}
      />
      <FullscreenVideoEl
        id="fullscreen-video"
        src={videoSrc}
        visible={!!videoSrc}
        ref={fullscreenVideoRef}
        disableRemotePlayback={true}
        playsInline
        preload="auto"
      >
        <track
          label="English"
          kind="subtitles"
          srcLang="en"
          src={
            filename && showCaptions
              ? `/video/captions/${filename.split('.')[0]}.vtt`
              : ''
          }
          default
        />
      </FullscreenVideoEl>
    </React.Fragment>
  );
};
