import { colours } from '@styles';
import React from 'react';
import { ButtonContainer } from './Button.styled';

interface Props {
  text: string;
  tour: string;
  textColour?: string;
  backgroundColour?: string;
  borderColour?: string;
  hoverColour?: string;
  handleClick?: (tour) => void;
}

export const Button = ({
  text,
  tour,
  textColour,
  backgroundColour,
  borderColour,
  hoverColour,
  handleClick,
}: Props) => {
  return (
    <ButtonContainer
      textColour={textColour || colours.darkGrey}
      backgroundColour={backgroundColour || colours.white}
      borderColour={borderColour || colours.themePrimary}
      hoverColour={hoverColour || colours.midGrey}
      onClick={() => {
        if (handleClick) handleClick(tour);
      }}
    >
      {text}
    </ButtonContainer>
  );
};
