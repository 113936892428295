import { colours, fonts } from '@styles';
import styled from 'styled-components';

export const VideoContainer = styled.div`
  position: relative;
  margin-bottom: 50px;
  background-color: ${colours.themePrimary};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 15px;
    background-image: url('/img/decorative-border-bottom-white.svg');
    transform: rotate(180deg);
    z-index: 1;
  }
`;

export const Video = styled.video`
  width: 100%;
`;

export const Overlay = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/img/onboarding/house-painted-bg.jpg');
  background-size: cover;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? 'all' : 'none')};
  transition: opacity 0.5s ease;
`;

export const Balloon = styled.img<{ top: string; left: string }>`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  z-index: 2;
`;

export const House = styled.img`
  position: absolute;
  bottom: 0;
  left: 10%;
  width: 80%;
  z-index: 1;
`;

export const Tree = styled.img<{ position: 'left' | 'right' }>`
  position: absolute;
  bottom: -5%;
  left: ${(props) => (props.position === 'left' ? '15%' : 'auto')};
  right: ${(props) => (props.position === 'right' ? '15%' : 'auto')};
  width: 20%;
  z-index: 2;
`;

export const OverlayTextContainer = styled.div`
  position: relative;
  width: 70%;
  margin: 100px auto 0;
  z-index: 5;
`;

export const OverlayTitle = styled.h2`
  font-family: ${fonts.poppins};
  font-size: 35px;
  color: ${colours.themePrimary};
`;

export const OverlayText = styled.p`
  font-family: ${fonts.poppins};
  font-size: 16px;
  color: ${colours.themePrimary};
`;

export const PlayIcon = styled.button`
  width: 75px;
  height: 75px;
  background-color: ${colours.themePrimary};
  border-radius: 50%;
  background-image: url('/img/ui/arrow.svg');
  background-size: 120%;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  &:hover {
    cursor: pointer;
    animation-name: hoverAnimation;
    animation-duration: 3s;
  }

  @keyframes hoverAnimation {
    0% {
      background-position: 50% 50%;
    }
    45% {
      background-position: 75px 50%;
    }
    48% {
      background-position: 75px 75px;
    }
    52% {
      background-position: -75px 75px;
    }
    55% {
      background-position: -75px 50%;
    }
    100% {
      background-position: 50% 50%;
    }
  }
`;
