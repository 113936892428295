import { selectPopupImageSource } from '@store/rooms';
import { selectSlowConnection } from '@store/tour';
import React from 'react';
import { useSelector } from 'react-redux';
import { PopupImageContainer } from './PopupImage.styled';

export const PopupImage = () => {
  const popupImageSrc = useSelector(selectPopupImageSource);
  const slowConnection = useSelector(selectSlowConnection);
  return (
    <PopupImageContainer
      className={`popup-image${popupImageSrc ? '' : ' hide'}`}
    >
      <img
        src={`/img/tour-content/${
          slowConnection ? 'low-res' : 'hi-res'
        }/${popupImageSrc}`}
        alt=""
      />
    </PopupImageContainer>
  );
};
