import styled from 'styled-components';

export const MarkerToggleContainer = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;

  @media (min-width: 992px) {
    bottom: 50px;
    right: 50px;
  }
`;
