import { colours } from '@styles';
import styled from 'styled-components';

export const UIContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 0.5s ease;
  pointer-events: none;
  z-index: 25;

  > * {
    pointer-events: all;
  }

  &.hide {
    opacity: 0;
  }
`;

export const TitleContainer = styled.div`
  position: relative;
  top: -20px;
  transform: scale(0.6);

  @media only screen and (min-width: 992px) {
    top: 0;
    transform: scale(1);
  }
`;

export const TitleTour = styled.h2<{ uiStyle: string }>`
  position: relative;
  top: 20px;
  width: 60%;
  margin: 0 auto;
  padding: 5px 0;
  font-family: 'Argesta Headline';
  font-size: 16px;
  color: ${(props) => {
    switch (props.uiStyle) {
      case 'hidden':
        return '#000';
      default:
        return '#fff';
    }
  }};
  text-transform: uppercase;
  background-image: ${(props) =>
    `url('/img/ui/backgrounds/tour-title-${props.uiStyle}.png')`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const TitleRoom = styled.h1<{ uiStyle: string }>`
  position: relative;
  top: 20px;
  width: 500px;
  margin: 0;
  padding: 15px 0;
  font-family: 'Argesta Headline';
  font-size: 30px;
  color: ${(props) => {
    switch (props.uiStyle) {
      case 'hidden':
        return '#fff';
      default:
        return '#000';
    }
  }};
  text-transform: uppercase;
  background-image: ${(props) =>
    `url('/img/ui/backgrounds/room-title-${props.uiStyle}.png')`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const TitleIcon = styled.img`
  position: relative;
  top: 0;
  width: 120px;
  z-index: 1;
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: 992px) {
    transform: scale(0.5);
  }
`;

export const Arrow = styled.div<{ dir: string; uiStyle: string }>`
  position: relative;
  width: 100px;
  height: 100px;
  background-image: ${(props) =>
    `url('/img/ui/backgrounds/arrow-background-${props.dir}-${props.uiStyle}.png')`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: filter 0.5s ease;

  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
`;

export const ArrowIcon = styled.div<{ dir: string; uiStyle: string }>`
  width: 100%;
  height: 100%;
  background-image: ${(props) =>
    `url('/img/ui/icons/icon-arrow-${props.uiStyle}.svg')`};
  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;
  transform: ${(props) => (props.dir === 'left' ? 'rotate(180deg)' : '')};
`;

export const Use = styled.p`
  pointer-events: all;
  margin: 0;
  padding: 20px;
  background-color: grey;
  border: 3px solid white;
  border-radius: 15px;
  color: white;
  font-weight: bold;
  font-size: 28px;

  &:hover {
    cursor: pointer;
  }
`;

export const GuideVideoContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc((500 / 1920) * 100%);
  z-index: 10;
  transition: bottom 1s ease;

  &.hide {
    bottom: -100vh;
    pointer-events: none;
  }
`;
export const GuideVideo = styled.video`
  width: 100%;
`;

export const GuideVideoClose = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    transition: opacity 0.3s ease;
  }

  &::before {
    background-image: url('/img/ui/button-close-hover.svg');
  }
  &::after {
    background-image: url('/img/ui/button-close-normal.svg');
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
    &::after {
      opacity: 0;
    }
  }
`;

export const GuideVideoPlay = styled.button`
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75px;
  height: 75px;
  background-color: ${colours.white};
  border-radius: 50%;
  background-image: url('/img/ui/arrow.svg');
  background-size: 120%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);

  &:hover {
    background-color: ${colours.grey};
    cursor: pointer;
  }

  &.show {
    display: block;
  }
`;

export const MenuToggle = styled.div<{ uiStyle: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px;
  left: 15px;
  width: 40px;
  height: 40px;
  background-image: ${(props) =>
    `url('/img/ui/backgrounds/menu-button-${props.uiStyle}.png')`};
  background-position: center;
  background-size: cover;
  transition: filter 0.5s ease;

  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }

  @media only screen and (min-width: 992px) {
    top: 20px;
    left: 30px;
    width: 75px;
    height: 75px;
  }
`;

export const MenuToggleIcon = styled.img`
  width: 40%;
`;
