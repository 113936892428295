import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';

const global = css`
  body {
    padding: 0;
    margin: 0;
    font-family: ${({ theme }) => theme.baseFont};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f4f4f4;
  }

  body *,
  body *::before,
  body *::after {
    border-collapse: collapse;
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  button {
    border: none;
    box-shadow: none;
  }

  // Generic CSS Transition fade in/out
  .fade-enter {
    opacity: 0;
    transition: opacity 0.3s ease-in 0.3s;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 0.3s ease-in 0.3s;
  }

  .fade-exit {
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }

  .fade-exit-active {
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${global}
`;
