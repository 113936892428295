import { selectUiStyle } from '@store/tour';
import React from 'react';
import { useSelector } from 'react-redux';
import ToggleButton from '../ToggleButton';
import { MarkerToggleContainer } from './MarkerToggle.styled';

export const MarkerToggle = () => {
  const uiStyle = useSelector(selectUiStyle);

  const [markersShowing, setMarkersShowing] = React.useState(true);

  const toggleMarkers = () => {
    const markers = document.querySelectorAll('.psv-marker');
    setMarkersShowing(!markersShowing);
    markers.forEach((marker) => {
      marker.classList.toggle('hide');
    });
  };

  const textColour = uiStyle === 'hidden' ? '#fff' : '#000';

  return (
    <MarkerToggleContainer>
      <ToggleButton
        active={markersShowing}
        text={{ active: 'on', inactive: 'off', colour: textColour }}
        onClick={toggleMarkers}
        background={`url('/img/ui/backgrounds/marker-toggle-background-${uiStyle}.png')`}
        icon={`/img/ui/icons/icon-info-${uiStyle}.png`}
      />
    </MarkerToggleContainer>
  );
};
