import React from 'react';
import {
  Overlay,
  Video,
  VideoContainer,
  Balloon,
  House,
  Tree,
  OverlayTextContainer,
  OverlayTitle,
  OverlayText,
  PlayIcon,
} from './LandingVideo.styled';

export const LandingVideo = ({ title }) => {
  const [overlayVisible, setOverlayVisible] = React.useState(true);

  const videoRef = React.useRef<HTMLVideoElement>(null);

  const playVideo = () => {
    const videoEl = videoRef.current;
    if (!videoEl) return;
    setOverlayVisible(false);
    videoEl.play();

    videoEl.addEventListener(
      'ended',
      () => {
        setOverlayVisible(true);
      },
      { once: true }
    );
  };

  return (
    <VideoContainer>
      <Video
        src="/video/onboarding/wentworth-stories-trailer.mp4"
        muted
        ref={videoRef}
      />
      <Overlay visible={overlayVisible}>
        <Balloon
          src="/img/onboarding/balloon-1.png"
          top="-5%"
          left="5%"
          width="115px"
        />
        <Balloon
          src="/img/onboarding/balloon-2.png"
          top="40%"
          left="90%"
          width="90px"
        />
        <Balloon
          src="/img/onboarding/balloon-3.png"
          top="50%"
          left="80%"
          width="40px"
        />
        <House src="/img/onboarding/wentworth-saturated.png" />
        <Tree src="/img/onboarding/tree.png" position="left" />
        <Tree src="/img/onboarding/tree.png" position="right" />

        <OverlayTextContainer>
          <OverlayTitle>{title}</OverlayTitle>
          <OverlayText>
            Virtually explore the magnificence of the house and it’s grand suite
            of rooms.
          </OverlayText>
          <PlayIcon onClick={playVideo} />
        </OverlayTextContainer>
      </Overlay>
    </VideoContainer>
  );
};
