import React from 'react';
import {
  UIContainer,
  TitleContainer,
  TitleTour,
  TitleRoom,
  ArrowContainer,
  Arrow,
  GuideVideoContainer,
  GuideVideo,
  GuideVideoClose,
  MenuToggle,
  TitleIcon,
  MenuToggleIcon,
  ArrowIcon,
  GuideVideoPlay,
} from './UI.styled';

import Map from '@components/UIElements/Map';
import { useSelector } from 'react-redux';
import { rotateCamera } from '@store/camera';
import {
  RoomInformation,
  selectUIDisplayState,
  setPopupImageSource,
} from '@store/rooms';
import { useAppDispatch } from '@store/store';
import { selectTourName, selectTourSlug, selectUiStyle } from '@store/tour';
import PopupImage from '@components/PopupImage';
import MarkerToggle from '@components/UIElements/MarkerToggle';
import MapToggle from '@components/UIElements/MapToggle';
import Menu from '@components/UIElements/Menu';
import { isIOS, isSafari } from 'react-device-detect';
interface Props {
  room: RoomInformation;
}

export const UI: React.FC<Props> = ({ room }) => {
  const dispatch = useAppDispatch();
  const displayUI = useSelector(selectUIDisplayState);
  const tourSlug = useSelector(selectTourSlug);
  const uiStyle = useSelector(selectUiStyle);
  const tourName = useSelector(selectTourName);

  const [showMap, setShowMap] = React.useState(false);

  React.useEffect(() => {
    const guideVideoContainer = document.getElementById(
      'guide-video-container'
    );
    const guideVideo = document.getElementById('guide-video');
    const source = guideVideo?.querySelector('source');
    const guideVideoClose = document.getElementById('guide-video-close');
    const mapButton = document.getElementById('map-button');
    if (
      guideVideo instanceof HTMLVideoElement &&
      guideVideoContainer &&
      guideVideoClose &&
      mapButton &&
      source
    ) {
      guideVideo.addEventListener('ended', () => {
        guideVideoContainer.classList.add('hide');
        mapButton.classList.remove('hide');
      });

      guideVideoClose.addEventListener('click', () => {
        guideVideo.pause();
        guideVideoContainer.classList.add('hide');
        const fadeAudio = setInterval(function () {
          // Only fade if past the fade out point or not at zero already
          if (guideVideo.volume - 0.1 >= 0) {
            guideVideo.volume -= 0.1;
          } else {
            clearInterval(fadeAudio);
            guideVideo.pause();
          }
        }, 50);
        dispatch(setPopupImageSource(''));
        mapButton.classList.remove('hide');
        clearTimeout(window.timeout);
        clearTimeout(window.guideTimeout);
        source.src = '';
        const videoTrack = guideVideo.querySelector('track');
        if (videoTrack instanceof HTMLTrackElement) videoTrack.src = '';
        window.dispatchEvent(new Event('next-event'));
      });
    }
  }, []);

  const playVideo = (e) => {
    const guideVideo = document.getElementById('guide-video');
    if (guideVideo instanceof HTMLVideoElement) {
      guideVideo.play();
      e.target.classList.remove('show');
    }
  };

  const toggleMenu = () => {
    const menuContainer = document.getElementById('menu-container');
    menuContainer?.classList.toggle('open');
  };

  return (
    <UIContainer id="ui-container" className={`${displayUI ? '' : 'hide'}`}>
      <TitleContainer>
        <TitleTour uiStyle={uiStyle}>{tourName}</TitleTour>
        <TitleRoom uiStyle={uiStyle}>{room.title}</TitleRoom>
        <TitleIcon src={`/img/ui/title-image-${tourSlug}.png`} />
      </TitleContainer>

      <MenuToggle
        onClick={toggleMenu}
        onTouchStart={toggleMenu}
        tabIndex={0}
        uiStyle={uiStyle}
      >
        <MenuToggleIcon src="/img/ui/icons/icon-menu.svg" />
      </MenuToggle>

      <Menu uiStyle={uiStyle} toggleMenu={toggleMenu} />

      <GuideVideoContainer className="hide" id="guide-video-container">
        <GuideVideo disableRemotePlayback playsInline id="guide-video">
          <source
            src=""
            type={isSafari || isIOS ? 'video/quicktime' : 'video/webm'}
          />
          <track label="English" kind="subtitles" srcLang="en" src="" default />
        </GuideVideo>
        <GuideVideoPlay
          id="guide-video-play"
          onClick={playVideo}
          onTouchStart={playVideo}
        />
        <GuideVideoClose id="guide-video-close" tabIndex={0} />
      </GuideVideoContainer>

      <PopupImage />

      <MapToggle mapOpen={showMap} setShowMap={setShowMap} uiStyle={uiStyle} />
      <Map show={showMap} setShowMap={setShowMap} />

      <ArrowContainer>
        <Arrow
          onClick={() => {
            dispatch(rotateCamera('ccw'));
          }}
          tabIndex={0}
          uiStyle={uiStyle}
          dir="left"
        >
          <ArrowIcon dir="left" uiStyle={uiStyle} />
        </Arrow>
        <Arrow
          onClick={() => {
            dispatch(rotateCamera('cw'));
          }}
          tabIndex={0}
          uiStyle={uiStyle}
          dir="right"
        >
          <ArrowIcon dir="right" uiStyle={uiStyle} />
        </Arrow>
      </ArrowContainer>

      <MarkerToggle />
    </UIContainer>
  );
};
