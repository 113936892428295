import Card from '@components/OnboardingComponents/Card';
import EmailVerification from '@components/OnboardingComponents/EmailVerification';
import LandingVideo from '@components/OnboardingComponents/LandingVideo';
import Header from '@layouts/Header';
import { colours } from '@styles';
import React from 'react';
import ReactGA from 'react-ga';

import {
  Cards,
  Content,
  EmailContainer,
  Heading,
  LandingPageContainer,
  Text,
} from './LandingPage.styled';

export const LandingPage = () => {
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  });

  return (
    <LandingPageContainer>
      <Header
        backgroundColour={colours.white}
        decorativeBottomBorder={false}
        largetitle="Wentworth Stories"
      />

      <EmailContainer id="email-container">
        <Text>
          If you’ve purchased a ticket, enter your email in the field below to
          proceed.
        </Text>
        <EmailVerification />
      </EmailContainer>

      <Content>
        <LandingVideo title="Experience something Grand" />
        <Heading>Some tips before you start</Heading>
        <Text>
          Get the most out of your experience by following this handy advice
        </Text>
      </Content>

      <Cards>
        <Card
          image="/img/onboarding/WW_Onboard_Image1.png"
          title="Guided tours from your own home"
          text="The tour system allows you to experience Wentworth Woodhouse from the comfort of your own home. Just find somewhere comfortable to sit!"
        />
        <Card
          image="/img/onboarding/WW_Onboard_Image2.png"
          title="High quality"
          text="We've designed Wentworth Stories to show off the grand house in all it’s glory. It’s best experienced on desktop and tablet devices over wifi."
        />
        <Card
          image="/img/onboarding/WW_Onboard_Image3.png"
          title="Sights and sounds"
          text="The tours are a multimedia experience, so remember to have your speakers on - or a pair of headphones plugged in!"
        />
      </Cards>
    </LandingPageContainer>
  );
};
