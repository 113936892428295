import React from 'react';
import { Button, Background, Text, Icon } from './ToggleButton.styled';

interface Props {
  active: boolean;
  icon?: string;
  background?: string;
  text: {
    inactive: string;
    active: string;
    colour?: string;
  };
  onClick?: () => void;
}

export const ToggleButton = ({
  active,
  icon,
  background,
  text,
  onClick,
}: Props) => {
  return (
    <Button
      onClick={onClick}
      onTouchStart={onClick}
      backgroundImagePresent={!!background}
    >
      <Background style={{ backgroundImage: background }} />
      <Text active={active} colour={text.colour || '#000'}>
        <span>{text.active}</span>
        <span>{text.inactive}</span>
      </Text>
      {icon && (
        <Icon active={active}>
          <img src={icon} alt="" />
        </Icon>
      )}
    </Button>
  );
};
