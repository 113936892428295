import Card from '@components/OnboardingComponents/Card';
import TicketInformation from '@components/OnboardingComponents/TicketInformation';
import Header from '@layouts/Header';
import { selectTickets } from '@store/tour';
import { colours } from '@styles';
import React from 'react';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import {
  Content,
  Text,
  TicketPageContainer,
  Title,
  Cards,
  Divider,
  Tickets,
} from './TicketPage.styled';
import { Redirect } from 'react-router';
import { processTicketInformation } from './processTicketInformation';
import { DisplayedTicketDetails } from '@store/types';

export const TicketPage = () => {
  const ticketInformation = useSelector(selectTickets);
  const displayedTickets = ticketInformation.map((ticket) =>
    processTicketInformation(ticket)
  );
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  });

  const sortByState = (
    a: DisplayedTicketDetails,
    b: DisplayedTicketDetails
  ): number => {
    const bState = b.state.purchaseState;

    if (bState === 'expired') return -1;
    else return 1;
  };

  // // Arrange in alphabetical order of ticket name
  // const sortedTicketInformation = ticketInformation.slice().sort(sortByName); // need to slice first to make a copy as original is immutable

  const filteredTickets: DisplayedTicketDetails[] = [];
  const ticketNames: string[] = [];
  ticketInformation.forEach((ticket) => {
    if (!ticketNames.includes(ticket.name)) ticketNames.push(ticket.name);
  });

  // Behaviour needs to be that we show all valid tickets (purchased/active), and if no valid ticket is present, only 1 expired ticket. This is to prevent clutter if many tours are purchased and expire, but also allow the user to see if there are any duplicate tickets in their account

  // going ticket by ticket:
  ticketNames.forEach((ticketName) => {
    // the sort arranges the array so that valid ones are added first - this prevents us having to remove expired ones if they are added before valid ones
    const ticketsWithSameName = displayedTickets.filter(
      (ticket) => ticket.name === ticketName
    );
    ticketsWithSameName.sort(sortByState);

    let expiredTicketAdded = false;
    let validTicketAdded = false;
    ticketsWithSameName.forEach((ticket) => {
      // pushing all valid and mark added
      if (ticket.state.purchaseState !== 'expired') {
        filteredTickets.push(ticket);
        validTicketAdded = true;
      } else if (!expiredTicketAdded && !validTicketAdded) {
        // only add non expired if there isn't a valid ticket and there isn't already an expired one
        filteredTickets.push(ticket);
        expiredTicketAdded = true;
      }
    });
  });

  console.log(filteredTickets);

  if (filteredTickets.length) {
    return (
      <TicketPageContainer>
        <Header
          backgroundColour={colours.midGrey}
          decorativeBottomBorder={true}
          largetitle="Wentworth Stories"
        />

        <Content>
          <Title>Your Tickets</Title>
          <Text>
            Our system shows you have tickets for the following tours. If these
            are different to what you expect, please contact us.
          </Text>
          <Text>
            Once a ticket is activated, you will be given access to the tour for
            30 days.
          </Text>

          <Tickets>
            <Divider />

            {filteredTickets.map((ticket, index) => {
              if (ticket)
                return (
                  <React.Fragment key={'ticket-' + index}>
                    <TicketInformation
                      image={ticket.image}
                      title={ticket.title}
                      text={ticket.text}
                      state={ticket.state}
                      tour={ticket.tour}
                      ticketID={ticket.id}
                      ticketLink={ticket.ticketLink}
                    />

                    <Divider />
                  </React.Fragment>
                );
            })}
          </Tickets>

          <Title>How do I use the tours?</Title>
        </Content>
        <Cards>
          <Card
            image="/img/onboarding/1.png"
            title="Click and drag to look around the rooms"
          />
          <Card
            image="/img/onboarding/2.png"
            title="Interact with the info points to learn more"
          />
          <Card
            image="/img/onboarding/3.png"
            title="This experience uses audio"
          />
          <Card
            image="/img/onboarding/4.png"
            title="If you get lost, use the map function"
          />
          <Card
            image="/img/onboarding/5.png"
            title="Use the arrows to move through the rooms"
          />
          <Card
            image="/img/onboarding/6.png"
            title=" Use Chrome for the best experience"
          />
        </Cards>
      </TicketPageContainer>
    );
  } else return <Redirect to="/" />;
};
