import store from '@store/store';
import {
  Asset,
  selectGlobalAudio,
  selectModels,
  selectRooms,
} from '@store/tour';

import { isIOS, isSafari } from 'react-device-detect';

export const createAssetList = (slowConnection: boolean): void => {
  const state = store.getState();
  const rooms = selectRooms(state);
  const models = selectModels(state);

  const assetList: Asset[] = [];

  const globalAudio = selectGlobalAudio(state);

  if (globalAudio.filename)
    assetList.push({
      id: globalAudio.filename,
      path: `/audio/${globalAudio.filename}`,
      type: 'audio',
    });

  rooms.forEach((room) => {
    const { audio, photosphereDetails, events } = room;
    // Add photospheres
    let photosphereBasePath = '/img/panorama/';
    if (slowConnection) {
      photosphereBasePath += 'low-res/';
    } else {
      photosphereBasePath += 'hi-res/';
    }
    assetList.push({
      id: `${room.id}-photosphere`,
      path: `${photosphereBasePath}${photosphereDetails.filename}`,
      type: 'photosphere',
    });
    if (audio)
      assetList.push({
        id: `${audio.filename}`,
        path: `/audio/${audio.filename}`,
        type: 'photosphere',
      });

    events.forEach((event) => {
      switch (event.type) {
        case 'transition-video':
        case 'change-photosphere':
          let basePath = '/video/transitions/';
          if (slowConnection) {
            basePath += 'low-res/';
          } else {
            basePath += 'hi-res/';
          }
          assetList.push({
            id: event.id,
            path: `${basePath}${event.source}`,
            type: 'fullscreen-video',
          });
          break;
        case 'guide-video':
          if (isSafari || isIOS) {
            let baseGuidePath = '/video/guide/mov/';
            if (slowConnection) {
              baseGuidePath += 'low-res/';
            } else {
              baseGuidePath += 'hi-res/';
            }
            assetList.push({
              id: `${event.id}-mov`,
              path: `${baseGuidePath}${event.source}.mov`,
              type: 'guide-video',
            });
          } else {
            let baseGuidePath = '/video/guide/webm/';
            if (slowConnection) {
              baseGuidePath += 'low-res/';
            } else {
              baseGuidePath += 'hi-res/';
            }
            assetList.push({
              id: `${event.id}-webm`,
              path: `${baseGuidePath}${event.source}.webm`,
              type: 'guide-video',
            });
          }
          break;
        case 'modal':
          if (event.modalInfo && event.modalInfo.image) {
            let basePath = '/img/tour-content/';
            if (slowConnection) {
              basePath += 'low-res/';
            } else {
              basePath += 'hi-res/';
            }
            assetList.push({
              id: event.modalInfo.image,
              path: `${basePath}${event.modalInfo.image}`,
              type: 'tour-content-image',
            });
          }
          break;
        default:
          break;
      }
    });
  });

  if (models) {
    models.forEach((model) => {
      assetList.push({
        id: model.id,
        filename: model.filename,
        castShadow: model.castShadow,
        path: `/models/${model.filename}.glb`,
        type: 'model',
      });
    });
  }

  store.dispatch({
    type: 'tour/setAssetList',
    payload: assetList,
  });
};
