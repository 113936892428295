import { colours } from '@styles';
import styled from 'styled-components';

export const TicketInformationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 25px 0;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
`;

export const Image = styled.img`
  width: 200px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

export const Title = styled.h3`
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: ${colours.themePrimary};
`;

export const Text = styled.p`
  font-size: 16px;
  text-align: left;
  color: ${colours.darkGrey};
`;

export const TicketState = styled.div<{ state: string }>`
  width: 100%;
  padding: 10px 30px;
  background-color: ${colours.lightGrey};
  color: ${(props) => {
    switch (props.state) {
      case 'purchased':
        return colours.themeSecondary;
      case 'expired':
        return colours.midGrey;
      default:
        return colours.darkGrey;
    }
  }};
  font-size: 20px;
  font-weight: 600;
`;
