import { DisplayedTicketDetails, TicketDetails } from '@store/types';
import { DateTime, Duration } from 'luxon';

const ticketConfigs = {
  'Virtual - Grand Tour': {
    title: 'The Grand Tour',
    image: '/img/onboarding/ticket-grand.png',
    text:
      'In this 25 minute tour, Dee, our guide, will show you the highlights of the main rooms of the house, with some special treats!',
    tour: 'grand-tour',
    ticketLink:
      'https://wentworthwoodhouse.digitickets.co.uk/event-tickets/48433?catID=30263',
  },
  'Virtual - Grand Tour -Complimentary': {
    title: 'The Grand Tour',
    image: '/img/onboarding/ticket-grand.png',
    text:
      'In this 25 minute tour, Dee, our guide, will show you the highlights of the main rooms of the house, with some special treats!',
    tour: 'grand-tour',
    ticketLink:
      'https://wentworthwoodhouse.digitickets.co.uk/event-tickets/48433?catID=30263',
  },
  'Virtual - Cellars Tour': {
    title: 'The Cellars Tour',
    image: '/img/onboarding/The-Cellars-Tour.png',
    text:
      'Delve into the forbidden areas of the house in this 35 minute exploration.',
    tour: 'hidden-wentworth',
    ticketLink:
      'https://wentworthwoodhouse.digitickets.co.uk/event-tickets/48432?catID=30263',
  },
  'Virtual - Cellars Tour - Complimentary': {
    title: 'The Cellars Tour',
    image: '/img/onboarding/The-Cellars-Tour.png',
    text:
      'Delve into the forbidden areas of the house in this 35 minute exploration.',
    tour: 'hidden-wentworth',
    ticketLink:
      'https://wentworthwoodhouse.digitickets.co.uk/event-tickets/48432?catID=30263',
  },
  'TEST Virtual - Grand Tour': {
    title: 'The Grand Tour',
    image: '/img/onboarding/ticket-grand.png',
    text:
      'In this 25 minute tour, Dee, our guide, will show you the highlights of the main rooms of the house, with some special treats!',
    tour: 'grand-tour',
    ticketLink:
      'https://wentworthwoodhouse.digitickets.co.uk/event-tickets/48432?catID=30263',
  },
  'TEST Virtual - Hidden Tour': {
    title: 'The Cellars Tour',
    image: '/img/onboarding/The-Cellars-Tour.png',
    text:
      'Delve into the forbidden areas of the house in this 35 minute exploration.',
    tour: 'hidden-wentworth',
    ticketLink:
      'https://wentworthwoodhouse.digitickets.co.uk/event-tickets/48432?catID=30263',
  },
  'TEST Virtual - Film & TV Tour': {
    title: 'The Film & TV Tour',
    image: '/img/onboarding/ticket-filmtv.png',
    text:
      'Understand the layout and rich variety of filming locations that Wentworth Woodhouse offers any production.',
    tour: 'filming-locations',
  },
};

export const processTicketInformation = (
  ticketInfo: TicketDetails
): DisplayedTicketDetails => {
  const config = ticketConfigs[ticketInfo.name];
  let ticketState;
  const dateNow = DateTime.now();
  const validLength = Duration.fromObject({
    days: 30,
  });
  if (!ticketInfo.redeemed) {
    ticketState = {
      purchaseState: 'purchased',
    };
  } else if (ticketInfo.redeemed && ticketInfo.dateRedeemed) {
    const dateRedeemed = DateTime.fromFormat(
      ticketInfo.dateRedeemed,
      'yyyy-LL-dd hh:mm:ss'
    );
    if (dateNow < dateRedeemed.plus(validLength)) {
      const daysLeft = dateRedeemed
        .plus(validLength)
        .diff(dateNow, 'days')
        .toObject().days;
      ticketState = {
        purchaseState: 'current',
        daysLeft,
      };
    } else {
      ticketState = {
        purchaseState: 'expired',
      };
    }
  }
  return {
    title: config.title,
    image: config.image,
    text: config.text,
    state: ticketState,
    tour: config.tour,
    id: ticketInfo.id,
    name: ticketInfo.name,
    ticketLink:
      config.ticketLink ??
      'https://wentworthwoodhouse.digitickets.co.uk/category/30263',
  };
};
