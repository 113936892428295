import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.5s ease;
  opacity: 1;
  z-index: 30;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
`;

export const ModalContainer = styled.div<{ design: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${(props) => (props.design === 'speech-bubble' ? '40%' : '70%')};
  max-width: 600px;
  padding: 30px 50px;
  background-color: white;
  border-radius: 20px;

  &::before {
    content: '';
    display: ${(props) =>
      props.design === 'speech-bubble' ? 'block' : 'none'};
    position: absolute;
    bottom: 0;
    left: -30px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 50px 50px;
    border-color: transparent transparent #ffffff transparent;
  }
`;

export const ModalClose = styled.div`
  position: absolute;
  top: -50px;
  right: -50px;
  width: 50px;
  height: 50px;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    transition: opacity 0.3s ease;
  }

  &::before {
    background-image: url('/img/ui/button-close-dark-hover.svg');
  }
  &::after {
    background-image: url('/img/ui/button-close-dark-normal.svg');
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
    &::after {
      opacity: 0;
    }
  }
`;

export const ModalTitle = styled.div`
  display: inline-block;
  position: relative;
  min-height: 0;
  margin-bottom: 35px;
  font-family: 'Argesta Display';
  text-transform: uppercase;

  h1,
  h2 {
    margin: 0 0 10px;
  }

  h1 {
    font-size: 50px;
    font-weight: bold;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -10%;
    left: 30%;
    width: 40%;
    height: 2px;
    background: black;
  }
`;

export const ModalContent = styled.div<{ dual?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => (props.dual ? 'space-between' : 'flex-start')};
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ModalImage = styled.img<{ dual?: boolean }>`
  /* width: ${(props) => (props.dual ? '48%' : '100%')}; */
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 50vh;
`;

export const ModalText = styled.p<{ dual?: boolean }>`
  /* width: ${(props) => (props.dual ? '48%' : '100%')}; */
  font-size: 20px;
  white-space: pre-wrap;
  max-height: 40vh;
  font-family: 'Argesta Display';
  text-align: left;
  .table {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }
  .row:nth-child(1) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    .cell {
      width: 100%;
      display: flex;
      padding: 3%;
    }
  }
  .row:nth-child(n + 2) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    .cell {
      width: 100%;
      display: flex;
      padding: 3%;
    }
  }
`;
