import store from '@store/store';

export const checkNetworkSpeed = (): void => {
  const testFile = '/download-test.bin';
  const req = new XMLHttpRequest();
  req.open('GET', testFile, true);
  const startTime = Date.now();

  const timeout = setTimeout(() => {
    store.dispatch({
      type: 'tour/setSlowConnection',
      payload: true,
    });
    req.abort();
  }, 10000);

  req.onload = function () {
    if (this.status === 200) {
      clearTimeout(timeout);
      const endTime = Date.now();
      const duration = endTime - startTime;
      console.log(duration / 1000);
      store.dispatch({
        type: 'tour/setSlowConnection',
        payload: false,
      });
    }
  };
  req.send();
};
