import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AppContainer, PortraitWarning } from './App.styled';
import Cookies from 'js-cookie';
import { isIE, isMobile, isMobileOnly } from 'react-device-detect';
import ReactGA from 'react-ga';

import { useSelector } from 'react-redux';
import { useAppDispatch } from '@store/store';
import {
  selectTourID,
  setTourName,
  setTourSlug,
  setTourStartRoom,
  setRooms,
  setModels,
  selectVerified,
  setDeviceType,
  setMapInformation,
  setGlobalAudio,
  setUiStyle,
  selectSlowConnection,
  selectGlobalAudio,
  selectCurrentRoom,
  setShowLookPoint,
  setMenuOptions,
  setOnboardingText,
  setHelpOverlayState,
  setSlowConnection,
  setVerified,
  setToursAccessInformation,
  selectToursAccessInformation,
} from '@store/tour';

import Onboarding from '@pages/Onboarding';
import Room from '@pages/Room';
import Offboarding from '@pages/Offboarding';
import PageNotFound from '@pages/PageNotFound';
import Preloader from '@components/Preloader';
import Audio from '@components/Audio';

import { createAssetList } from '@functions/createAssetList';
import { checkNetworkSpeed } from '@functions/checkNetworkSpeed';
import LandingPage from '@pages/LandingPage';
import TicketPage from '@pages/TicketPage';
import { updateCurrentTour } from '@functions/updateCurrentTour';
import UnsupportedWarning from '@pages/UnsupportedWarning';
import TourPassword from '@pages/TourPassword';
declare global {
  interface Window {
    camera: THREE.PerspectiveCamera;
    scene: THREE.Scene;
    viewer: any;
    lat: number;
    lon: number;
    markersPlugin: any;
    tourName: string;
    tourSlug: string;
    modalText: string;
    modalImage: string;
    timeout: number;
    models: any;
    sceneReady: boolean;
    fullscreenTimeout: number;
    guideTimeout: number;
  }
}

const App = () => {
  const dispatch = useAppDispatch();
  const tourID = useSelector(selectTourID);
  const slowConnection = useSelector(selectSlowConnection);
  const currentRoom = useSelector(selectCurrentRoom);
  const globalAudio = useSelector(selectGlobalAudio);
  const verifiedTicket = useSelector(selectVerified);
  const toursAccessInformation = useSelector(selectToursAccessInformation);

  // const [tourJSONLoaded, setTourJSONLoaded] = React.useState(false);
  const [assetsLoaded, setAssetsLoaded] = React.useState(false);
  const [roomAudio, setRoomAudio] = React.useState('');
  const [initialVolume, setInitialVolume] = React.useState(0);
  const [isFirstRoom, setIsFirstRoom] = React.useState(true);

  React.useEffect(() => {
    if (!toursAccessInformation.length) return;
    if (tourID) {
      fetch(`/tours/${tourID}.json`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Not 2xx response');
          } else {
            return response.json();
          }
        })
        .then((json) => {
          // setTourJSONLoaded(true);
          dispatch(setTourName(json.name));
          dispatch(setTourStartRoom(json.startRoom));
          dispatch(setRooms(json.rooms));
          dispatch(setUiStyle(json.uiStyle));
          dispatch(setTourSlug(json.slug));
          dispatch(setMenuOptions(json.menuOptions));
          dispatch(setModels(json.models));
          dispatch(setOnboardingText(json.onboardingText));
          dispatch(setMapInformation(json.map));
          dispatch(setShowLookPoint(!!json.showLookPoint));
          if (json.globalAudio) {
            dispatch(setGlobalAudio(json.globalAudio));
          }
          if (json.showHelpOverlay) {
            dispatch(setHelpOverlayState(true));
          }
          if (isMobile) {
            dispatch(setSlowConnection(true));
          } else checkNetworkSpeed();
        })
        .catch((error) => {
          console.error('Error fetching tour JSON: ', error);
        });

      const tourAccess = toursAccessInformation.filter(
        (tour) => tour.id === tourID
      )[0];

      if (tourAccess.type === 'open') {
        dispatch(setVerified(true));
      } else {
        const tourCookie = Cookies.get(tourID);
        console.log({ tourCookie });
        if (tourCookie) dispatch(setVerified(true));
        else dispatch(setVerified(false));
      }
    }
  }, [tourID, toursAccessInformation]);

  const touchHandler = (event) => {
    const touches = event.changedTouches,
      first = touches[0];

    const mouseEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
      screenX: first.screenX,
      screenY: first.screenY,
      clientX: first.clientX,
      clientY: first.clientY,
    });

    first.target.dispatchEvent(mouseEvent);
  };

  React.useEffect(() => {
    fetch(`/tours/tour-access.json`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Not 2xx response');
        } else {
          return response.json();
        }
      })
      .then((json) => {
        dispatch(setToursAccessInformation(json.tours));
      })
      .catch((error) => {
        console.error('Error fetching tour JSON: ', error);
      });

    if (isMobile) {
      dispatch(setDeviceType('mobile'));
    }

    // initialise GA
    ReactGA.initialize('UA-213322293-1');

    document.addEventListener('touchstart', touchHandler);
  }, []);

  React.useEffect(() => {
    if (slowConnection !== null) {
      createAssetList(slowConnection);
    }
  }, [slowConnection]);

  React.useEffect(() => {
    if (!currentRoom) return;
    console.log(isFirstRoom);
    if (currentRoom.audio) {
      setRoomAudio(currentRoom.audio.filename);
      // first room should be louder on hidden
      if (tourID === 'hidden-wentworth' && isFirstRoom) {
        setInitialVolume(1);
      } else {
        setInitialVolume(currentRoom.audio.initialVolume);
      }
    } else {
      setRoomAudio(globalAudio.filename);
      if (tourID === 'hidden-wentworth' && isFirstRoom) {
        setInitialVolume(1);
      } else {
        setInitialVolume(globalAudio.initialVolume);
      }
    }
    setIsFirstRoom(false);
  }, [currentRoom]);

  if (isMobileOnly || isIE) {
    return <UnsupportedWarning />;
  } else
    return (
      <React.Fragment>
        <AppContainer className="main-app">
          <Switch>
            <Route path="/your-tickets">
              <TicketPage />
            </Route>

            <Route path="/offboarding">
              <Offboarding />
            </Route>

            <Route path="/tour-password">
              <TourPassword />
            </Route>

            <Route exact path="/:tour/:room">
              {({ match }) => {
                if (match) {
                  updateCurrentTour(match.params.tour);
                  return (
                    <React.Fragment>
                      {verifiedTicket === false && <Redirect to="/" />}
                      {verifiedTicket === true && (
                        <React.Fragment>
                          <PortraitWarning className="portrait-warning">
                            <div className="inner">
                              <h1>Please rotate your device to landscape</h1>
                            </div>
                          </PortraitWarning>
                          <Audio
                            currentAudio={roomAudio}
                            initialVolume={initialVolume}
                          />
                          {!assetsLoaded ? (
                            <Preloader setAssetsLoaded={setAssetsLoaded} />
                          ) : (
                            <Room key={window.location.pathname} />
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  );
                } else return null;
              }}
            </Route>

            <Route path="/:tour">
              {({ match }) => {
                if (match) {
                  updateCurrentTour(match.params.tour);
                  if (match.url === '/404') return <PageNotFound />;
                  else
                    return (
                      <React.Fragment>
                        {verifiedTicket === false && <Redirect to="/" />}
                        {verifiedTicket === true && (
                          <React.Fragment>
                            <PortraitWarning className="portrait-warning">
                              <div className="inner">
                                <h1>Please rotate your device to landscape</h1>
                              </div>
                            </PortraitWarning>
                            {!assetsLoaded ? (
                              <Preloader setAssetsLoaded={setAssetsLoaded} />
                            ) : (
                              <Onboarding />
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    );
                } else return null;
              }}
            </Route>

            <Route path="/">
              <LandingPage />
            </Route>
          </Switch>
        </AppContainer>
      </React.Fragment>
    );
};

export default App;
