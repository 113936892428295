import React from 'react';
import { LookPointDebug, PhotosphereContainer } from './Photosphere.styled';

import './psv.css';

import { Viewer } from 'photo-sphere-viewer';
import MarkersPlugin from 'photo-sphere-viewer/dist/plugins/markers';
import VisibleRangePlugin from 'photo-sphere-viewer/dist/plugins/visible-range';
import GyroscopePlugin from 'photo-sphere-viewer/dist/plugins/gyroscope';
import StereoPlugin from 'photo-sphere-viewer/dist/plugins/stereo';
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css';
import 'photo-sphere-viewer/dist/plugins/markers.css';

import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useAppDispatch } from '@store/store';
import { selectLookPoint, storeLookPoint } from '@store/camera';
import {
  RoomInformation,
  checkDistanceToMarkersPhotosphere,
  selectClosestMarker,
} from '@store/rooms';

import { sequenceHandler } from '@functions/sequenceHandler';

import {
  sphericalCoordsToTextureCoords,
  textureCoordsToSphericalCoords,
} from '@functions/coordinateTransforms';
import { add3DModelToScene } from '@functions/add3DModelToScene';
import { addLights } from '@functions/addLights';
import {
  selectLoadedAssets,
  selectPreviousRoom,
  selectUiStyle,
  selectShowLookPoint,
  selectTourStartRoom,
  selectTourSlug,
  selectSlowConnection,
  selectVisistedRooms,
  selectTriggeredSequences,
} from '@store/tour';
import Modal from '@components/UIElements/Modal';
import {
  sRGBEncoding,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
} from 'three';

interface Props {
  room: RoomInformation;
}

let viewer, markersPlugin;

export const Photosphere = ({ room }: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const closestMarker = useSelector(selectClosestMarker);
  const loadedAssets = useSelector(selectLoadedAssets);
  const uiStyle = useSelector(selectUiStyle);
  const previousRoom = useSelector(selectPreviousRoom);
  const showLookPoint = useSelector(selectShowLookPoint);
  const lookPoint = useSelector(selectLookPoint);
  const startRoom = useSelector(selectTourStartRoom);
  const tourSlug = useSelector(selectTourSlug);
  const slowConnection = useSelector(selectSlowConnection);
  const visitedRooms = useSelector(selectVisistedRooms);
  const triggeredSequences = useSelector(selectTriggeredSequences);

  const handleMarkerClick = (e: Event) => {
    if (
      e.target instanceof HTMLDivElement &&
      e.target.classList.contains('marker')
    ) {
      const marker = e.target;
      const sequence = marker.getAttribute('data-sequence');
      if (sequence) {
        sequenceHandler(sequence, room, history, marker);
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === ' ') {
      const closestMarker = document.querySelector('.psv-marker.closest');
      if (!closestMarker) return;
      else if (closestMarker instanceof HTMLElement) {
        const closestID = closestMarker.id.replace('psv-marker-', '');
        const markerObject = markersPlugin.markers[closestID];
        if (markerObject.data) {
          const innerMarker = markerObject.$el.children[0];
          sequenceHandler(
            markerObject.data.sequence,
            room,
            history,
            innerMarker
          );
        }
      }
    }
  };

  const buildMarkers = (roomMarkers) => {
    const markers = roomMarkers.map((roomMarker) => {
      const {
        id,
        position,
        sequence,
        type,
        tooltip,
        visible = true,
      } = roomMarker;
      let html = '';
      let size = 60;
      switch (type) {
        case 'doorway-forward':
          html = `<div class="marker doorway-forward" data-sequence="${sequence}"></div>`;
          break;
        case 'doorway-forward-bold':
          html = `<div class="marker doorway-forward-bold" data-sequence="${sequence}"><div class="text">Next</div></div>`;
          break;
        case 'arrow-forward':
          html = `<div class="marker arrow-forward" data-sequence="${sequence}"></div>`;
          break;
        case 'doorway-back':
          html = `<div class="marker doorway-back" data-sequence="${sequence}"></div>`;
          break;
        case 'go-to-present':
          html = `<div class="marker-inverted go-to-present" data-sequence="${sequence}"></div>`;
          break;
        case 'go-to-past':
          html = `<div class="marker-inverted go-to-past" data-sequence="${sequence}"></div>`;
          break;
        case 'picture-change':
          html = `<div class="marker picture-change" data-sequence="${sequence}"></div>`;
          break;
        case 'guide-video':
        case 'modal':
          html = `<div class="marker info" data-sequence="${sequence}"></div>`;
          size = 40;
          break;
        default:
          break;
      }
      return {
        id,
        x: position.x,
        y: position.y,
        width: size,
        height: size,
        html: html,
        data: {
          sequence,
        },
        visible,
        tooltip: tooltip || null,
      };
    });
    return markers;
  };

  React.useEffect(() => {
    let photosphereDims = {
      x: 4096,
      y: 2048,
    };
    if (room.photosphereDetails?.dimensions) {
      photosphereDims = room.photosphereDetails.dimensions;
    }
    let sphericalStartCoords = {
      latitude: 0,
      longitude: 0,
    };
    if (room.photosphereDetails?.startLook) {
      const { startLook } = room.photosphereDetails;
      if (startLook.x !== undefined && startLook.y !== undefined) {
        sphericalStartCoords = textureCoordsToSphericalCoords(
          startLook.x || 0,
          startLook.y || 0,
          photosphereDims.x,
          photosphereDims.y,
          0,
          0
        );
      } else {
        if (previousRoom) {
          const roomDependentCoords = startLook[previousRoom];
          if (roomDependentCoords) {
            sphericalStartCoords = textureCoordsToSphericalCoords(
              roomDependentCoords.x || 0,
              roomDependentCoords.y || 0,
              photosphereDims.x,
              photosphereDims.y,
              0,
              0
            );
          } else {
            const defaultCoords = startLook.default;
            sphericalStartCoords = textureCoordsToSphericalCoords(
              defaultCoords.x || 0,
              defaultCoords.y || 0,
              photosphereDims.x,
              photosphereDims.y,
              0,
              0
            );
          }
        } else {
          const defaultCoords = startLook.default;
          sphericalStartCoords = textureCoordsToSphericalCoords(
            defaultCoords.x || 0,
            defaultCoords.y || 0,
            photosphereDims.x,
            photosphereDims.y,
            0,
            0
          );
        }
      }
    }

    const loadedPhotosphere = loadedAssets.filter(
      (asset) => asset.id === `${room.id}-photosphere`
    )[0];

    const panoramaPath = loadedPhotosphere
      ? loadedPhotosphere.url
      : `/img/panorama/${slowConnection ? 'low-res' : 'hi-res'}/${
          room.photosphereDetails?.filename
        }`;
    console.log({ panoramaPath });
    viewer = new Viewer({
      container: document.querySelector('#photosphere'),
      panorama: panoramaPath,
      moveInertia: true,
      defaultZoomLvl: 0,
      defaultLong: sphericalStartCoords.longitude,
      defaultLat: sphericalStartCoords.latitude,
      minFov: 80,
      maxFov: 80,
      // navbar: [],
      keyboard: false,
      plugins: [
        [
          MarkersPlugin,
          {
            // list of markers
            markers: buildMarkers(room.scripting.markers),
          },
        ],
        [
          VisibleRangePlugin,
          {
            latitudeRange: ['-180deg', '179deg'],
          },
        ],
        GyroscopePlugin,
        StereoPlugin,
      ],
    });

    window.viewer = viewer;

    viewer.on('ready', () => {
      const {
        scene,
        renderer,
      }: {
        scene: THREE.Scene;
        renderer: THREE.WebGLRenderer;
      } = viewer.renderer;

      renderer.shadowMap.enabled = true;
      // renderer.sortObjects = false;

      if (room.lights) {
        renderer.outputEncoding = sRGBEncoding;
        renderer.physicallyCorrectLights = true;

        addLights(scene, room.lights);
      }

      if (room.models && window.models) {
        room.models.forEach((model) => {
          const modelContainer = window.models.filter(
            (windowModel) => windowModel.id === model.id
          )[0];
          add3DModelToScene(
            modelContainer.dataObject,
            model.position,
            model.lookAt,
            model.scale,
            model.rotate,
            scene
          );
          const object = viewer.renderer.scene.getObjectByName(model.id);
          if (object) {
            object.visible = true;
            viewer.needsUpdate();
          }
        });

        // Setting encoding for photosphere object
        const mesh = viewer.renderer.scene.children[1].children[0];
        if (mesh) {
          mesh.material.map.encoding = sRGBEncoding;
          mesh.material.map.needsUpdate = true;
          mesh.material.needsUpdate = true;
        }
      }

      if (
        room.id === startRoom &&
        tourSlug === 'hidden' &&
        visitedRooms.length === 1
      ) {
        document.getElementById('flicker')?.classList.add('animate');
      }
      const roomEl = document.getElementById('room');

      // Check if room has fullscreen video to play at start - if so keep cover black as video will remove it at end
      const autoplayingNonRepeatingSequence = room.scripting.sequences.filter(
        (sequence) => sequence.autoplay && !sequence.repeat
      )[0];
      const fullscreenVideoEvents = room.events.filter(
        (event) =>
          autoplayingNonRepeatingSequence?.events?.includes(event.id) &&
          event.type === 'transition-video'
      );
      const roomTriggeredSequences = triggeredSequences[room.id];
      console.log(roomTriggeredSequences);
      if (
        roomEl &&
        (!fullscreenVideoEvents.length ||
          (fullscreenVideoEvents.length &&
            roomTriggeredSequences?.includes(
              autoplayingNonRepeatingSequence.id
            )))
      ) {
        console.log('remove cover before video');
        roomEl.classList.remove('cover-black');

        window.sceneReady = true;
      }
    });

    viewer.on('position-updated', (e, position) => {
      dispatch(
        storeLookPoint({ lat: position.latitude, lon: position.longitude })
      );
      dispatch(
        checkDistanceToMarkersPhotosphere({
          lat: position.latitude,
          lon: position.longitude,
        })
      );
    });

    markersPlugin = viewer.getPlugin(MarkersPlugin);
    window.markersPlugin = markersPlugin;

    // markersPlugin.on(
    //   'select-marker',
    //   (
    //     e: Event,
    //     marker: { $el: { children: HTMLDivElement[] }; data: { sequence } }
    //   ) => {
    //     console.log({ marker });
    //     if (marker.data) {
    //       const innerMarker = marker.$el.children[0];
    //       sequenceHandler(marker.data.sequence, room, history, innerMarker);
    //     }
    //   }
    // );

    document.addEventListener('click', handleMarkerClick);
    document.addEventListener('keydown', handleKeyDown);

    return function cleanup() {
      document.removeEventListener('click', handleMarkerClick);
      document.removeEventListener('touchstart', handleMarkerClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  React.useEffect(() => {
    const setNewClosest = () => {
      const newClosestMarker = document.getElementById(
        `psv-marker-${closestMarker.id}`
      );
      newClosestMarker?.classList.add('closest');
    };

    const currentClosestMarker = document.querySelector('.closest');
    if (closestMarker.distance < 0.5) {
      if (!currentClosestMarker) {
        setNewClosest();
      } else {
        const currentMarkerID = currentClosestMarker.id.replace(
          'psv-marker-',
          ''
        );
        if (currentMarkerID !== closestMarker.id) {
          currentClosestMarker.classList.remove('closest');
          setNewClosest();
        }
      }
    } else {
      const currentClosestMarker = document.querySelector('.closest');
      currentClosestMarker?.classList.remove('closest');
    }
  }, [closestMarker]);

  const lookPointCartesian = sphericalCoordsToTextureCoords(
    lookPoint.lon,
    lookPoint.lat,
    4096,
    2048
  );

  return (
    <React.Fragment>
      {showLookPoint && (
        <LookPointDebug>
          x: {Math.round(lookPointCartesian.x)}
          <br />
          y: {Math.round(lookPointCartesian.y)}
        </LookPointDebug>
      )}
      <PhotosphereContainer id="photosphere" />
      <Modal room={room} design={uiStyle === 'hidden' ? 'speech-bubble' : ''} />
    </React.Fragment>
  );
};
