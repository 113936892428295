import { combineReducers } from '@reduxjs/toolkit';
import roomsReducer from './rooms';
import tourReducer from './tour';
import cameraReducer from './camera';

const rootReducer = combineReducers({
  camera: cameraReducer,
  rooms: roomsReducer,
  tour: tourReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
