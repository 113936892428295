import { colours } from '@styles';
import styled from 'styled-components';

export const MenuContainer = styled.div<{ uiStyle: string }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100vh;
  padding: 25px;
  background-color: #fff;
  transition: left 1s ease;
  z-index: 11;

  &.open {
    left: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #000;

    &__title {
      font-family: 'Argesta Text';
      font-weight: bold;
      font-size: 22px;
      text-transform: uppercase;
    }

    &__button {
      position: relative;
      width: 50px;
      height: 50px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        transition: opacity 0.3s ease;
      }

      &::before {
        background-image: url('/img/ui/button-close-dark-hover.svg');
      }
      &::after {
        background-image: url('/img/ui/button-close-dark-normal.svg');
        opacity: 1;
      }

      &:hover {
        cursor: pointer;
        &::after {
          opacity: 0;
        }
      }
    }
  }

  .exit-tour {
    position: absolute;
    bottom: 60px;
    width: 80%;
    margin: 0;
    font-family: 'Argesta Text';
    font-size: 20px;
    text-transform: uppercase;
    text-align: left;
    color: #000;
    text-decoration: none;
    background-image: ${(props) =>
      `url('/img/ui/icons/icon-arrow-${props.uiStyle}.svg');`};
    background-position: 95% center;
    background-repeat: no-repeat;
    background-size: 30px;
    border: 1px solid black;
    padding: 10px;
    transition: background 0.5s ease;
    background-color: white;

    &:hover {
      background-color: lightgrey;
      cursor: pointer;
    }
  }
`;

export const Button = styled.button`
  position: absolute;
  bottom: 120px;
  width: 80%;
  margin: 0;
  font-family: 'Argesta Text';
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  color: ${colours.black};
  text-decoration: none;
  border: 1px solid black;
  padding: 10px;
  transition: background 0.5s ease;
  background-color: ${colours.white};

  &:hover {
    background-color: ${colours.grey};
    cursor: pointer;
  }
`;

export const OptionsContainer = styled.div``;

export const Option = styled.button<{ active?: boolean }>`
  display: block;
  position: relative;
  margin: 20px 0;
  padding-left: 50px;
  font-family: 'Argesta Text';
  font-size: 20px;
  text-transform: uppercase;
  text-align: left;
  color: ${colours.black};
  background: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 20px;
    width: 50px;
    height: 50px;
    background-position: center;
    background-color: white;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease;
  }

  &::before {
    background-image: url('/img/ui/checkbox-crossed.svg');
  }
  &::after {
    background-image: url('/img/ui/checkbox-ticked.svg');
    opacity: ${(props) => (props.active ? 1 : 0)};
  }

  &:hover {
    cursor: pointer;
  }
`;
