import { colours, fonts } from '@styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const OffboardingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-image: url('/img/onboarding/background-pattern.svg');
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  padding: 50px 75px;
  background-color: ${colours.white};
`;

export const Title = styled.h1`
  font-family: ${fonts.poppins};
  font-size: 35px;
  font-weight: 500;
  color: ${colours.themePrimary};
`;

export const Text = styled.p`
  max-width: 75%;
  font-family: ${fonts.poppins};
  font-size: 16px;
  color: ${colours.themePrimary};
`;

export const ButtonLink = styled.a`
  padding: 20px 50px;
  font-family: ${fonts.poppins};
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: ${colours.themePrimary};
  border: 2px solid ${colours.themePrimary};

  &:hover {
    background-color: ${colours.lightGrey};
  }
`;

export const InternalLink = styled(Link)`
  margin-top: 25px;
  font-family: ${fonts.poppins};
  font-size: 16px;
  text-decoration: none;
  color: ${colours.themePrimary};

  &:hover {
    text-decoration: underline;
  }
`;
