import { aspectRatio, colours } from '@styles';
import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 30%;
  margin-bottom: 30px;
  background-color: ${colours.white};
`;

export const ImageContainer = styled.div`
  overflow: hidden;

  ${aspectRatio(306, 244)}
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.4s ease;
  transform: scale(1);

  &:hover {
    transform: scale(1.1);
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 30px 35px;
`;

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  color: ${colours.themePrimary};
`;

export const Text = styled.p`
  margin-top: 15px;
  font-size: 16px;
  line-height: 24px;
  color: ${colours.darkGrey};
`;
