import React from 'react';
import { VersionContainer } from './Version.styled';

/**
 * Displays the current package version
 *
 * @component
 */
export const Version = () => (
  <VersionContainer data-testid="Version">
    Alpha {process.env.REACT_APP_VERSION}
  </VersionContainer>
);
