import styled from 'styled-components';

export const PopupImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* left: 10%; */
  /* width: 50%; */
  height: 60%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 1%;
  opacity: 1;
  transition: opacity 0.5s linear;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  img {
    height: 100%;
  }
`;
