import React from 'react';
import {
  UnsupportedWarningContainer,
  Inner,
  Image,
  Text,
} from './UnsupportedWarning.styled';

export const UnsupportedWarning = () => {
  return (
    <UnsupportedWarningContainer>
      <Inner>
        <Image src="/img/ui/icons/icon-warning.svg" alt="" />
        <Text>
          We&apos;ve detected that you&apos;re using a device or browser that we
          do not support. Please view the tour on either a desktop or tablet
          computer, through a modern browser - we recommend Google Chrome.
        </Text>
      </Inner>
    </UnsupportedWarningContainer>
  );
};
