import store from '@store/store';
import { selectUIDisplayState } from '@store/rooms';
import {
  selectCurrentRoom,
  selectDeviceType,
  selectLoadedAssets,
  selectShowCaptions,
  selectSlowConnection,
} from '@store/tour';
import { isIOS, isSafari } from 'react-device-detect';

export const eventHandler = (
  eventID: string,
  eventList: any,
  history: any
): void => {
  if (!eventList || !eventID) return;
  const state = store.getState();
  const loadedAssets = selectLoadedAssets(state);
  const slowConnection = selectSlowConnection(state);

  console.log(eventID);

  const event = eventList.filter(
    (eventObject) => eventObject.id === eventID
  )[0];

  const guideVideoContainer = document.getElementById('guide-video-container');
  const guideVideo = document.getElementById('guide-video');
  const sourceTrack = guideVideo?.querySelector('source');
  const videoTrack = guideVideo?.querySelector('track');
  const mapButton = document.getElementById('map-button');
  const guideVideoPlayButton = document.getElementById('guide-video-play');

  const fullscreenVideo = document.getElementById('fullscreen-video');
  const fullscreenPlayButton = document.getElementById('fullscreen-video-play');

  switch (eventID) {
    case 'roam-off':
      store.dispatch({
        type: 'rooms/setInputAllowed',
        payload: false,
      });
      window.dispatchEvent(new Event('next-event'));
      break;
    case 'roam-on':
      store.dispatch({
        type: 'rooms/setInputAllowed',
        payload: true,
      });
      window.dispatchEvent(new Event('next-event'));
      break;
    case 'toggle-ui':
      const uiState = selectUIDisplayState(state);
      store.dispatch({
        type: 'rooms/setUIDisplayState',
        payload: !uiState,
      });
      window.dispatchEvent(new Event('next-event'));
      break;
    case 'timeout':
      setTimeout(() => {
        window.dispatchEvent(new Event('next-event'));
      }, 10000);
      break;
    case 'short-timeout':
      setTimeout(() => {
        window.dispatchEvent(new Event('next-event'));
      }, 1000);
      break;
    case 'long-timeout':
      setTimeout(() => {
        window.dispatchEvent(new Event('next-event'));
      }, 20000);
      break;
    case 'toggle-fade':
      const roomEl = document.getElementById('room');
      if (roomEl) {
        roomEl.classList.toggle('cover-black');
        window.dispatchEvent(new Event('next-event'));
      }
      break;
    case 'fade-in-audio':
      window.dispatchEvent(new Event('fade-in-audio'));
      window.dispatchEvent(new Event('next-event'));
      break;
    case 'fade-out-audio':
      window.dispatchEvent(new Event('fade-out-audio'));
      window.dispatchEvent(new Event('next-event'));
      break;
    case 'show-models':
      if (window.viewer) {
        const { viewer } = window;

        const { models } = state.tour;
        models.forEach((model) => {
          const object = viewer.renderer.scene.getObjectByName(model.id);
          if (object) {
            console.log(object);
            object.visible = true;
            viewer.needsUpdate();
          }
        });
      }
      window.dispatchEvent(new Event('next-event'));
      break;
    default:
      switch (event.type) {
        case 'change-room':
          window.sceneReady = false;
          // fade out
          const roomEl = document.getElementById('room');
          if (roomEl) {
            roomEl.classList.add('cover-black');
          }
          const leavingRoom = selectCurrentRoom(state);
          if (leavingRoom) {
            store.dispatch({
              type: 'tour/setPreviousRoom',
              payload: leavingRoom.id,
            });
          }
          setTimeout(() => {
            if (history) {
              const currentPath = window.location.pathname;
              const basePath = `/${currentPath.split('/')[1]}/`;
              history.push(basePath + event.room);
            }

            window.dispatchEvent(new Event('next-event'));
          }, 500);
          break;
        case 'guide-video':
          const loadedMOV = loadedAssets.filter(
            (asset) => asset.id === event.id + '-mov'
          )[0];
          const loadedWEBM = loadedAssets.filter(
            (asset) => asset.id === event.id + '-webm'
          )[0];
          if (!sourceTrack) return;
          if (
            guideVideo instanceof HTMLVideoElement &&
            guideVideoContainer &&
            videoTrack instanceof HTMLTrackElement &&
            mapButton
          ) {
            mapButton.classList.add('hide');
            guideVideoContainer.classList.remove('hide');

            let { source } = event;
            const { responsive } = event;
            if (responsive) {
              const deviceType = selectDeviceType(state);
              switch (deviceType) {
                case 'mobile':
                  source = event.mobile;
                  break;
                default:
                  break;
              }
            }
            if (isSafari || isIOS) {
              if (loadedMOV) {
                console.log('gv loaded mov');
                sourceTrack.src = loadedMOV.url;
              } else {
                console.log('gv streamed mov');
                sourceTrack.src = `/video/guide/mov/${
                  slowConnection ? 'low-res/' : 'hi-res/'
                }${source}.mov`;
              }
            } else {
              if (loadedWEBM) {
                console.log('gv loaded webm');
                sourceTrack.src = loadedWEBM.url;
              } else {
                console.log('gv streamed webm');
                sourceTrack.src = `/video/guide/webm/${
                  slowConnection ? 'low-res/' : 'hi-res/'
                }${source}.webm`;
              }
            }

            const showCaptions = selectShowCaptions(state);
            if (showCaptions) {
              videoTrack.src = `/video/captions/${source}.vtt`;
            }
            guideVideo.volume = 1;
            guideVideo.load();

            setTimeout(() => {
              guideVideo.play().catch((error) => {
                console.error(error);
                guideVideoPlayButton?.classList.add('show');
              });
            }, 750);

            if (event.popupImage) {
              if (event.popupImage.time) {
                const timeout = window.setTimeout(() => {
                  store.dispatch({
                    type: 'rooms/setPopupImageSource',
                    payload: event.popupImage.source,
                  });
                }, event.popupImage.time * 1000);
                window.timeout = timeout;
              } else {
                store.dispatch({
                  type: 'rooms/setPopupImageSource',
                  payload: event.popupImage.source,
                });
              }
            }

            const endHandler = () => {
              guideVideo.removeEventListener('ended', endHandler);
              mapButton.classList.remove('hide');
              clearTimeout(window.timeout);
              clearTimeout(window.guideTimeout);
              store.dispatch({
                type: 'rooms/setPopupImageSource',
                payload: '',
              });
              window.dispatchEvent(new Event('next-event'));
            };
            guideVideo.addEventListener('ended', endHandler);
          }
          break;
        case 'transition-video':
        case 'change-photosphere':
          const loadedVideo = loadedAssets.filter(
            (asset) => asset.id === event.id
          )[0];
          if (loadedVideo) {
            store.dispatch({
              type: 'rooms/setFullscreenVideoSource',
              payload: loadedVideo.url,
            });
            store.dispatch({
              type: 'rooms/setFullscreenVideoFilename',
              payload: event.source,
            });
          } else {
            store.dispatch({
              type: 'rooms/setFullscreenVideoSource',
              payload: `/video/transitions/${
                slowConnection ? 'low-res' : 'hi-res'
              }/${event.source}`,
            });
            store.dispatch({
              type: 'rooms/setFullscreenVideoFilename',
              payload: event.source,
            });
          }
          if (
            fullscreenVideo instanceof HTMLVideoElement &&
            fullscreenPlayButton
          ) {
            if (
              guideVideo instanceof HTMLVideoElement &&
              guideVideoContainer &&
              mapButton &&
              sourceTrack
            ) {
              guideVideoContainer.classList.add('hide');
              const fadeAudio = setInterval(function () {
                // Only fade if past the fade out point or not at zero already
                if (guideVideo.volume - 0.1 >= 0) {
                  guideVideo.volume -= 0.1;
                } else {
                  clearInterval(fadeAudio);
                  guideVideo.pause();
                }
              }, 50);
              store.dispatch({
                type: 'rooms/setPopupImageSource',
                payload: '',
              });
              mapButton.classList.remove('hide');
              clearTimeout(window.timeout);
              sourceTrack.src = '';
              const videoTrack = guideVideo.querySelector('track');
              if (videoTrack instanceof HTMLTrackElement) videoTrack.src = '';
            }
            // console.log({ fullscreenVideo });
            // fullscreenVideo.play().catch((err) => {
            //   console.error(err);
            //   fullscreenPlayButton.classList.add('show');
            // });
          }
          if (event.type === 'change-photosphere') {
            setTimeout(() => {
              if (window.viewer) {
                const { viewer } = window;
                viewer.setPanorama(
                  `/img/panorama/${slowConnection ? 'low-res' : 'hi-res'}/${
                    event.photosphere
                  }`
                );

                const { models } = state.tour;
                models.forEach((model) => {
                  const object = viewer.renderer.scene.getObjectByName(
                    model.id
                  );
                  if (object) {
                    if (object.visible) object.visible = false;
                    else object.visible = true;
                    viewer.needsUpdate();
                  }
                });
              }
            }, 1000);
          }
          break;
        case 'modal':
          const modalWrapper = document.getElementById('modal-wrapper');
          modalWrapper?.classList.remove('hide');
          if (event.modalInfo) {
            store.dispatch({
              type: 'rooms/setModalInfo',
              payload: {
                title: event.modalInfo.title,
                text: event.modalInfo.text || '',
                image: event.modalInfo.image || '',
              },
            });
          }
          break;
        case 'toggle-markers':
          const { markersPlugin } = window;
          const { markers } = event;
          if (!markersPlugin) return;
          markers.forEach((markerID) => {
            console.log(markerID);
            markersPlugin.toggleMarker(markerID);
          });
          window.dispatchEvent(new Event('next-event'));
          break;
        case 'link':
          window.location.href = event.link;
          break;
        case 'change-audio-volume':
          console.log(event.audio);
          window.dispatchEvent(
            new CustomEvent(`change-${event.audio}-volume`, {
              detail: { newVolume: event.newVolume },
            })
          );
          window.dispatchEvent(new Event('next-event'));
          break;
        default:
          break;
      }
      break;
  }
};
