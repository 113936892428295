import { colours, fonts } from '@styles';
import styled from 'styled-components';

export const PasswordVerificationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  width: 650px;
  margin-bottom: 50px;
  border: 2px solid ${colours.themeSecondary};
  border-radius: 15px;
  transition: background 0.3s ease;

  &:focus-within,
  &:hover {
    background: ${colours.lightGrey};
  }
`;

export const Input = styled.input`
  width: 90%;
  margin: 0 auto;
  padding: 25px 0;
  font-size: 20px;
  font-family: ${fonts.poppins};
  text-align: center;
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
`;

export const Submit = styled.button`
  position: absolute;
  top: calc(50% - 25px);
  right: 25px;
  width: 50px;
  height: 50px;
  background-color: ${colours.themeSecondary};
  border-radius: 50%;
  background-image: url('/img/ui/arrow-white.svg');
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  &:hover {
    cursor: pointer;
    animation-name: hoverAnimation;
    animation-duration: 3s;
  }

  @keyframes hoverAnimation {
    0% {
      background-position: 50% 50%;
    }
    45% {
      background-position: 50px 50%;
    }
    48% {
      background-position: 50px 50px;
    }
    52% {
      background-position: -50px 50px;
    }
    55% {
      background-position: -50px 50%;
    }
    100% {
      background-position: 50% 50%;
    }
  }
`;

export const ErrorMessage = styled.p`
  display: none;
  position: absolute;
  bottom: -50px;

  .error & {
    display: block;
  }
`;
