import { useAppDispatch } from '@store/store';
import { setTickets } from '@store/tour';
import { Order, OrderItem, TicketDetails } from '@store/types';
import React from 'react';
import { useHistory } from 'react-router';
import {
  EmailVerificationContainer,
  ErrorMessage,
  Input,
  Submit,
} from './EmailVerification.styled';

const ticketNames = [
  'Virtual - Grand Tour',
  'Virtual - Grand Tour -Complimentary',
  'TEST Virtual - Grand Tour',
  'TEST Virtual - Film & TV Tour',
  'TEST Virtual - Hidden Tour',
];

export const EmailVerification = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const emailInputRef = React.useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const getOrders = () => {
    const emailInput = emailInputRef.current;
    if (emailInput instanceof HTMLInputElement && emailInput.value) {
      fetch(
        `${
          process.env.REACT_APP_TICKET_CHECK_URL || 'http://localhost:3001'
        }/orders`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: emailInput.value,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          const orders: Order[] = data.orders;
          if (!orders) {
            if (containerRef.current) {
              containerRef.current.classList.add('error');
            }
          } else {
            const ticketsPurchased: TicketDetails[] = [];
            orders.forEach((order: Order) => {
              order.orderitems.forEach((item: OrderItem) => {
                console.log(item.name);
                if (ticketNames.includes(item.name)) {
                  ticketsPurchased.push({
                    name: item.name,
                    datePurchased: item.createdAt,
                    redeemed: !!item.redeemed,
                    dateRedeemed: item.lastRedeemedAt,
                    id: item.ordersItemsID,
                  });
                }
              });
            });
            dispatch(setTickets(ticketsPurchased));
            history.push('/your-tickets');
          }
        });
    }
  };

  React.useEffect(() => {
    const input = emailInputRef.current;
    if (input) {
      input.addEventListener('keypress', (e) => {
        if (e.key === 'Enter') getOrders();
      });
    }
  }, []);

  return (
    <EmailVerificationContainer ref={containerRef}>
      <Input
        ref={emailInputRef}
        type="email"
        placeholder="Enter your email address"
        autoComplete="email"
        name="email"
      />
      <Submit onClick={getOrders} />
      <ErrorMessage>
        Sorry, we couldn&apos;t find any tickets for that email address.
      </ErrorMessage>
    </EmailVerificationContainer>
  );
};
