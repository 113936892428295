import store from '@store/store';
import { Asset } from '@store/tour';
import { loadGLBModel } from './loadGLBModel';

export const preloadAssets = (assetList: Asset[]): void => {
  const sendRequest = (asset) => {
    const req = new XMLHttpRequest();
    req.open('GET', asset.path, true);
    req.responseType = 'blob';

    req.onload = function () {
      handleLoad(this, asset);
    };

    req.onerror = (error) => {
      console.log(error);
      console.log(`error for ${asset.path}`);
      //sendRequest(asset);
    };

    req.send();
  };

  const handleLoad = (res, asset) => {
    if (res.status === 200) {
      const responseBlob = res.response;
      const url = URL.createObjectURL(responseBlob);
      store.dispatch({
        type: 'tour/addLoadedAsset',
        payload: {
          id: asset.id,
          url: url,
        },
      });
    }
  };

  assetList.forEach((asset) => {
    if (asset.type === 'model') {
      if (!window.models) window.models = [];
      const dataObject = loadGLBModel(
        asset.filename || '',
        asset.id,
        asset.castShadow
      );
      window.models.push({
        id: asset.id,
        filename: asset.filename,
        castShadow: asset.castShadow,
        dataObject,
      });
    } else {
      sendRequest(asset);
    }
  });
};
