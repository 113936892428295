import { colours, fonts, notchedEdges } from '@styles';
import styled from 'styled-components';

export const HeaderContainer = styled.header<{
  backgroundColour: string;
  decorativeBottomBorder: boolean;
}>`
  display: flex;
  justify-content: space-evenly;
  position: relative;
  width: 100%;
  padding: 15px 0 45px;
  background-color: ${(props) => props.backgroundColour};

  &::after {
    content: '';
    display: ${(props) => (props.decorativeBottomBorder ? 'block' : 'none')};
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 100%;
    height: 15px;
    background-image: url('/img/decorative-border-bottom-grey.svg');
    z-index: 1;
  }
`;

export const ExternalLink = styled.a`
  width: 20%;
  height: 100%;
  font-family: ${fonts.poppins};
  font-weight: bold;
  font-size: 16px;
  color: ${colours.themePrimary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const WelcomeBlock = styled.div`
  position: relative;
  width: 40%;

  a {
    width: 100%;
    text-decoration: none;
  }
`;

export const WelcomeBlockContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  background-color: ${colours.grey};
  ${notchedEdges('25px')}
`;

export const Title = styled.h1`
  margin-bottom: 15px;
  font-family: ${fonts.argestaHeadline};
  font-size: 18px;
  color: ${colours.themePrimary};
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
`;

export const LargeTitle = styled(Title)`
  font-size: 35px;
`;

export const LogoContainer = styled.div`
  position: relative;
  width: 60%;
  margin: 0 auto 10px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: -10%;
    width: 120%;
    height: 1px;
    background-color: ${colours.themePrimary};
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
`;

export const Logo = styled.img`
  width: 100%;
  padding: 10px 0;
`;

export const OuterIcon = styled.img`
  position: absolute;
  width: 85px;
  height: 45px;
  bottom: -22.5px;
  left: calc(50% - 42.5px);
`;
