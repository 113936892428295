import { notchedEdges } from '@styles';
import styled from 'styled-components';

export const Button = styled.button<{ backgroundImagePresent: boolean }>`
  position: relative;
  padding: 0;
  height: 40px;
  background-color: ${(props) =>
    props.backgroundImagePresent ? 'transparent' : '#fff'};

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 992px) {
    height: 50px;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${notchedEdges('10px')}
`;

export const Text = styled.p<{ active: boolean; colour: string }>`
  position: relative;
  margin: 0;
  padding: 5px 10px;
  font-family: 'Argesta Text';
  font-weight: bold;
  font-size: 18px;
  color: ${(props) => props.colour};
  text-transform: uppercase;
  text-align: left;

  @media (min-width: 992px) {
    padding: 10px 15px;
    font-size: 24px;
  }

  span {
    transition: opacity 0.1s linear;
    opacity: 1;

    &:nth-of-type(1) {
      margin-right: 20px;
      opacity: ${(props) => (props.active ? 1 : 0)};
    }

    &:nth-of-type(2) {
      opacity: ${(props) => (props.active ? 0 : 1)};
    }
  }
`;

export const Icon = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0px;
  width: 40px;
  height: 40px;
  right: ${(props) => (props.active ? '5px' : 'calc(100% - 55px)')};
  transition: right 0.5s ease;

  @media (min-width: 992px) {
    top: -5px;
    width: 60px;
    height: 60px;
    right: ${(props) => (props.active ? '10px' : 'calc(100% - 70px)')};
  }

  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 2.5px);
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #000;
    transform: rotate(-45deg);
    transition: opacity 0.5s ease;
    opacity: ${(props) => (props.active ? '0' : '1')};
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
