/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';

export interface XYPosition {
  x: number;
  y: number;
}
export interface XYZPosition {
  x: number;
  y: number;
  z: number;
}

export interface LatLon {
  lat: number;
  lon: number;
}
export interface Audio {
  filename: string;
  initialVolume: number;
}

export interface Marker {
  id: string;
  type: string;
  sequence: string;
  position: XYZPosition;
  tooltip?: string;
}

export interface PhotosphereInformation {
  filename: string;
  startLook: any;
  dimensions?: {
    x: number;
    y: number;
  };
}

export interface Sequence {
  id: string;
  autoplay: boolean;
  repeat: boolean;
  events: string[];
}

export interface Event {
  id: string;
  type: string;
  wait: boolean;
  position: XYZPosition;
  source?: string;
  room?: string;
  direction?: {
    x?: number;
    y?: number;
    z?: number;
  };
  rotate?: boolean;
  lightID?: string;
  action?: string;
  waitTime?: number;
  colour?: string;
  subtitles?: boolean;
  modalInfo?: ModalInfo;
  popupImage?: PopupImage;
}

export interface LightObject {
  id: string;
  type: string;
  color?: string;
  intensity?: number;
  position?: XYZPosition;
  target?: XYZPosition;
  helper?: boolean;
}

export interface Scripting {
  sequences: Sequence[];
  markers: Marker[];
}

export interface RoomInformation {
  id: string;
  title: string;
  type: 'photosphere';
  photosphereDetails: PhotosphereInformation;
  scripting: Scripting;
  events: Event[];
  lights: LightObject[];
  audio?: Audio;
  startPosition?: XYZPosition;
  startLook?: XYZPosition;
  models: any;
  light?: {
    position: XYZPosition;
    target: XYZPosition;
    scale: number;
  };
}

export interface ClosestMarker {
  id: string;
  distance: number;
}
export interface PopupImage {
  source: string;
  time: number;
}

export interface Tour {
  rooms: RoomInformation[];
  currentRoom: string;
  closestMarker: ClosestMarker;
  hoverActive: boolean;
  inputAllowed: boolean;
  displayUI: boolean;
  modalInfo: ModalInfo;
  popupImageSource: string;
  fullscreenVideoSource: string;
  fullscreenVideoFilename: string;
}
export interface Sequence {
  id: string;
  autoplay: boolean;
  repeat: boolean;
  events: string[];
}

export interface ModalInfo {
  title: string;
  text?: string;
  image?: string;
}

const initialState: Tour = {
  rooms: [],
  currentRoom: '',
  closestMarker: {
    id: '',
    distance: Infinity,
  },
  hoverActive: false,
  inputAllowed: true,
  displayUI: true,
  modalInfo: {
    title: '',
    text: '',
    image: '',
  },
  popupImageSource: '',
  fullscreenVideoSource: '',
  fullscreenVideoFilename: '',
};

export const roomsSlice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {
    setRooms: (state, action: PayloadAction<Tour>) => {
      state.rooms = action.payload.rooms;
    },
    setCurrentRoom: (state, action: PayloadAction<string>) => {
      state.currentRoom = action.payload;
    },
    checkDistanceToMarkersPhotosphere: (
      state,
      action: PayloadAction<LatLon>
    ) => {
      const { markersPlugin } = window;
      if (!markersPlugin) return;
      const { lat: cameraLat, lon: cameraLon } = action.payload;
      let shortestDistance;
      let closestMarker = '';
      const { markers } = markersPlugin;
      Object.keys(markers).forEach((key) => {
        const marker = markers[key];
        const markerLat = marker.props.position.latitude;
        const markerLon = marker.props.position.longitude;
        const x =
          (markerLon - cameraLon) * Math.cos((cameraLat + markerLat) / 2);
        const y = markerLat - cameraLat;
        const distance = Math.sqrt(x * x + y * y);
        if (!shortestDistance || distance < shortestDistance) {
          shortestDistance = distance;
          closestMarker = marker.id;
        }
      });

      state.closestMarker.id = closestMarker;
      state.closestMarker.distance = shortestDistance;
    },
    setInputAllowed: (state, action: PayloadAction<boolean>) => {
      state.inputAllowed = action.payload;
    },
    setUIDisplayState: (state, action: PayloadAction<boolean>) => {
      state.displayUI = action.payload;
    },
    setModalInfo: (state, action: PayloadAction<ModalInfo>) => {
      state.modalInfo.title = action.payload.title;
      if (action.payload.text) {
        state.modalInfo.text = action.payload.text;
      } else {
        state.modalInfo.text = '';
      }
      if (action.payload.image) {
        state.modalInfo.image = action.payload.image;
      } else {
        state.modalInfo.image = '';
      }
    },
    setPopupImageSource: (state, action: PayloadAction<string>) => {
      state.popupImageSource = action.payload;
    },
    setFullscreenVideoSource: (state, action: PayloadAction<string>) => {
      state.fullscreenVideoSource = action.payload;
    },
    setFullscreenVideoFilename: (state, action: PayloadAction<string>) => {
      state.fullscreenVideoFilename = action.payload;
    },
  },
});

export const {
  setRooms,
  setCurrentRoom,
  checkDistanceToMarkersPhotosphere,
  setInputAllowed,
  setModalInfo,
  setUIDisplayState,
  setPopupImageSource,
  setFullscreenVideoSource,
  setFullscreenVideoFilename,
} = roomsSlice.actions;

// export const selectCurrentRoom = (state: RootState): RoomInformation =>
//   state.rooms.rooms.filter((room) => room.id === state.rooms.currentRoom)[0];

export const selectClosestMarker = (state: RootState): ClosestMarker =>
  state.rooms.closestMarker;

export const selectHoverActive = (state: RootState): boolean =>
  state.rooms.hoverActive;

export const selectCurrentEvents = (state: RootState): Event[] => {
  const currentRoom = state.rooms.rooms.filter(
    (room) => room.id === state.rooms.currentRoom
  )[0];
  return currentRoom.events;
};

export const selectUIDisplayState = (state: RootState): boolean => {
  return state.rooms.displayUI;
};

export const selectPopupImageSource = (state: RootState): string => {
  return state.rooms.popupImageSource;
};

export const selectFullscreenVideoSource = (state: RootState): string => {
  return state.rooms.fullscreenVideoSource;
};

export const selectFullscreenVideoFilename = (state: RootState): string => {
  return state.rooms.fullscreenVideoFilename;
};

export const selectModalInfo = (state: RootState): ModalInfo => {
  return state.rooms.modalInfo;
};

export default roomsSlice.reducer;
