import React from 'react';
import { Link } from 'react-router-dom';
import { Button, MenuContainer, OptionsContainer, Option } from './Menu.styled';

import { Version } from '@components/Version';
import { useSelector } from 'react-redux';
import {
  selectBackgroundAudioMuted,
  selectMenuOptions,
  selectShowCaptions,
  setAmbientAudioMuted,
  setHelpOverlayState,
  setShowCaptions,
} from '@store/tour';
import { useAppDispatch } from '@store/store';
interface Props {
  uiStyle: string;
  toggleMenu: () => void;
}

export const Menu = ({ uiStyle, toggleMenu }: Props) => {
  const dispatch = useAppDispatch();

  const muted = useSelector(selectBackgroundAudioMuted);
  const showCaptions = useSelector(selectShowCaptions);
  const menuOptions = useSelector(selectMenuOptions);

  const toggleAudio = (e) => {
    const checkbox = e.target.parentElement.querySelector('.checkbox');
    if (checkbox) checkbox.classList.toggle('off');
    if (muted) dispatch(setAmbientAudioMuted(false));
    else dispatch(setAmbientAudioMuted(true));
  };

  const toggleCaptions = (e) => {
    const checkbox = e.target.parentElement.querySelector('.checkbox');
    if (checkbox) checkbox.classList.toggle('off');
    if (showCaptions) dispatch(setShowCaptions(false));
    else dispatch(setShowCaptions(true));
  };

  const showHelpOverlay = () => {
    dispatch(setHelpOverlayState(true));
    toggleMenu();
  };

  return (
    <MenuContainer id="menu-container" uiStyle={uiStyle}>
      <div className="header">
        <h2 className="header__title">Menu</h2>
        <div
          className="header__button"
          id="menu-close"
          onClick={toggleMenu}
        ></div>
      </div>
      <OptionsContainer>
        {menuOptions.includes('captions') && (
          <Option
            active={showCaptions}
            onClick={toggleCaptions}
            onTouchStart={toggleCaptions}
            role="button"
          >
            Captions
          </Option>
        )}

        {menuOptions.includes('backgroundAudio') && (
          <Option
            active={!muted}
            onClick={toggleAudio}
            onTouchStart={toggleAudio}
            role="button"
          >
            Background Audio
          </Option>
        )}
      </OptionsContainer>

      <Button onClick={showHelpOverlay}>Help</Button>

      <Link to="/offboarding" className="exit-tour">
        Exit the tour
      </Link>
      <Version />
    </MenuContainer>
  );
};
