import { colours } from '@styles';
import React from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router';
import Button from '../Button';
import {
  Column,
  Image,
  Text,
  TicketInformationContainer,
  TicketState,
  Title,
} from './TicketInformation.styled';
import { useAppDispatch } from '@store/store';
import { setVerified } from '@store/tour';

interface Props {
  image: string;
  title: string;
  text: string;
  tour: string;
  state?: {
    purchaseState: 'purchased' | 'current' | 'expired';
    daysLeft?: number;
  };
  ticketID: number;
  ticketLink: string;
}

export const TicketInformation = ({
  image,
  title,
  text,
  tour,
  state = {
    purchaseState: 'expired',
  },
  ticketID,
  ticketLink,
}: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const redeemTicket = (ticketID: number) => {
    fetch(
      `${
        process.env.REACT_APP_TICKET_CHECK_URL || 'http://localhost:3001'
      }/redeem`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ticketId: ticketID,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((err) => console.error(err));
  };

  const handleClick = (tour: string) => {
    if (state.purchaseState === 'expired') {
      window.location.href = ticketLink;
    } else {
      if (state.purchaseState === 'purchased') {
        redeemTicket(ticketID);
      }
      Cookies.remove(tour);
      Cookies.set(tour, 'valid', {
        expires: state.daysLeft || 30,
      });
      dispatch(setVerified(true));
      // openFullscreen(document.body);
      history.push(`/${tour}`);
    }
  };

  let stateText = '';
  let buttonText = '';
  let textColour;
  let backgroundColour;
  let borderColour;
  let hoverColour;

  switch (state.purchaseState) {
    case 'purchased':
      stateText = 'Purchased';
      buttonText = 'Click to Activate';
      break;
    case 'current':
      if (state.daysLeft === undefined)
        stateText = 'Unable to display days remaining';
      else if (state.daysLeft < 1) {
        const hours = Math.floor(24 * state.daysLeft);
        stateText = `${hours} hour${hours === 1 ? '' : 's'} remaining`;
      } else if (state.daysLeft < 1 / 24) {
        // don't want to show zero hours remaining
        stateText = 'Less than 1 hour remaining';
      } else {
        stateText = `${Math.round(state.daysLeft)} day${
          state.daysLeft === 1 ? '' : 's'
        } remaining`;
      }
      buttonText = 'Click to Start';
      break;
    case 'expired':
      stateText = 'Expired';
      buttonText = 'Click to Purchase';
      textColour = colours.white;
      backgroundColour = colours.themeSecondary;
      borderColour = colours.themeSecondary;
      hoverColour = colours.themeSecondaryDark;
      break;
    default:
      break;
  }

  return (
    <TicketInformationContainer>
      <Column>
        <Image src={image} alt="" />
      </Column>
      <Column>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </Column>
      <Column>
        <TicketState state={state.purchaseState}>{stateText}</TicketState>
        <Button
          text={buttonText}
          tour={tour}
          textColour={textColour}
          backgroundColour={backgroundColour}
          borderColour={borderColour}
          hoverColour={hoverColour}
          handleClick={handleClick}
        />
      </Column>
    </TicketInformationContainer>
  );
};
