import styled from 'styled-components';

export const MapToggleContainer = styled.div`
  position: absolute;
  bottom: 15px;
  left: 15px;
  z-index: 8;

  @media (min-width: 992px) {
    bottom: 50px;
    left: 50px;
  }
`;
