/**
 * Default breakpoints, edit these to project requirements
 */
const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '475px',
  tablet: '768px',
  laptop: '992px',
  laptopL: '1440px',
  desktop: '2560px',
};

/**
 * Mobile first media queries
 *
 * @example
 * ```@media ${device.mobileS} {}```
 */
export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export const colours = {
  white: '#FFFFFF',
  black: '#000000',
  grey: '#E6E6E6',
  lightGrey: '#F4F4F4',
  midGrey: '#C6C6C6',
  darkGrey: '#515151',
  themePrimary: '#0E3453',
  themeSecondary: '#AD7A28',
  themeSecondaryDark: '#8C621F',
};

export const fonts = {
  argestaDisplay: 'Argesta Display',
  argestaHeadline: 'Argesta Headline',
  argestaHairline: 'Argesta Hairline',
  argestaText: 'Argesta Text',
  poppins: 'Poppins',
};

export const notchedEdges = (notchSize = '0px'): string => `
  clip-path: polygon(
    0% ${notchSize},
    ${notchSize} 0%,
    calc(100% - ${notchSize}) 0%,
    100% ${notchSize},
    100% calc(100% - ${notchSize}),
    calc(100% - ${notchSize}) 100%,
    ${notchSize} 100%,
    0% calc(100% - ${notchSize})
  );
`;

export const aspectRatio = (width: number, height: number): string => `
position: relative;

  &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: calc((${height} / ${width}) * 100%);
  }

  > img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
  }
`;
