import { colours, fonts } from '@styles';
import styled from 'styled-components';

export const TicketPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-image: url('/img/onboarding/background-pattern.svg');
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  padding: 50px 75px 150px;
  background-color: ${colours.white};
`;

export const Title = styled.h1`
  font-family: ${fonts.poppins};
  font-size: 35px;
  font-weight: 500;
  color: ${colours.themePrimary};
`;

export const Heading = styled.h2`
  font-family: ${fonts.poppins};
  font-size: 20px;
  font-weight: 500;
  color: ${colours.themePrimary};
`;

export const Text = styled.p`
  max-width: 75%;
  font-family: ${fonts.poppins};
  font-size: 16px;
  color: ${colours.themePrimary};
`;

export const Cards = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  max-width: 1000px;
  top: -100px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colours.themePrimary};
`;

export const Tickets = styled.div`
  margin: 50px 0;
`;
