export const textureCoordsToSphericalCoords = (
  x = 0,
  y = 0,
  fullWidth = 4000,
  fullHeight = 2000,
  croppedX = 0,
  croppedY = 0
): {
  longitude: number;
  latitude: number;
} => {
  const relativeX = ((x + croppedX) / fullWidth) * Math.PI * 2;
  const relativeY = ((y + croppedY) / fullHeight) * Math.PI;
  return {
    longitude: relativeX >= Math.PI ? relativeX - Math.PI : relativeX + Math.PI,
    latitude: Math.PI / 2 - relativeY,
  };
};

export const sphericalCoordsToTextureCoords = (
  longitude: number,
  latitude: number,
  fullWidth = 4000,
  fullHeight = 2000,
  croppedX = 0,
  croppedY = 0
): {
  x: number;
  y: number;
} => {
  if (longitude >= Math.PI) longitude -= Math.PI;
  else longitude += Math.PI;
  const x = fullWidth * (longitude / (2 * Math.PI)) - croppedX;
  const y = fullHeight / 2 - ((latitude / Math.PI) * fullHeight - croppedY);
  return {
    x,
    y,
  };
};
