import React from 'react';
import {
  ExternalLink,
  HeaderContainer,
  WelcomeBlock,
  WelcomeBlockContent,
  Title,
  Logo,
  LargeTitle,
  OuterIcon,
  LogoContainer,
} from './Header.styled';

interface Props {
  backgroundColour: string;
  decorativeBottomBorder: boolean;
  largetitle: string;
}

export const Header = ({
  backgroundColour,
  decorativeBottomBorder,
  largetitle,
}: Props) => {
  return (
    <HeaderContainer
      backgroundColour={backgroundColour}
      decorativeBottomBorder={decorativeBottomBorder}
      id="header"
    >
      <ExternalLink href="https://wentworthwoodhouse.org.uk/">
        Return to the main site
      </ExternalLink>
      <WelcomeBlock>
        <WelcomeBlockContent>
          <a href="/">
            <Title>Welcome to</Title>
            <LogoContainer>
              <Logo
                src="/img/ww-house-logo.svg"
                alt="Wentworth Woodhouse Rotherham"
              />
            </LogoContainer>
            <LargeTitle>{largetitle}</LargeTitle>
          </a>
        </WelcomeBlockContent>

        <OuterIcon src="/img/onboarding/finger-pointing-right.svg" alt="" />
      </WelcomeBlock>

      <ExternalLink href="https://wentworthwoodhouse.org.uk/accessibility/">
        Access for All
      </ExternalLink>
    </HeaderContainer>
  );
};
