import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colours, fonts, notchedEdges } from '@styles';

export const MapContainer = styled.div<{ show: boolean }>`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s ease;
  pointer-events: ${(props) => (props.show ? 'all' : 'none !important')};
  z-index: 5;
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 90%;
  height: 90%;
  padding: 0 0 20px;
  background-color: ${colours.lightGrey};
  clip-path: polygon(
    0% 20px,
    20px 0%,
    calc(100% - 20px) 0%,
    100% 20px,
    100% 100%,
    0 100%,
    0% calc(100% - 20px)
  );

  @media (min-width: 992px) {
    padding: 0 100px 100px;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    transition: opacity 0.3s ease;
  }

  &::before {
    background-image: url('/img/ui/button-close-dark-hover.svg');
  }
  &::after {
    background-image: url('/img/ui/button-close-dark-normal.svg');
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
    &::after {
      opacity: 0;
    }
  }

  @media (min-width: 992px) {
    top: 35px;
    right: 35px;
    width: 60px;
    height: 60px;
  }
`;

export const OverviewLink = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 0;
  color: ${colours.themePrimary};
  font-family: ${fonts.argestaDisplay};
  font-size: 18px;
  text-decoration: underline;
  text-transform: uppercase;
  background: none;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 992px) {
    top: 35px;
    left: 35px;
    font-size: 30px;
  }
`;

export const Title = styled.h1<{ overview: boolean }>`
  position: relative;
  margin: ${(props) => (props.overview ? '20px 0 0' : '20px 0 10px')};
  font-family: 'Argesta Display';
  font-weight: normal;
  text-transform: uppercase;
  color: black;
  font-size: 24px;

  @media only screen and (min-width: 992px) {
    margin: ${(props) => (props.overview ? '100px 0 10px' : '20px 0 10px')};
    font-size: 50px;
  }
`;

export const MapView = styled.div<{ roomsHidden: boolean }>`
  display: inline-block;
  position: relative;
  max-height: ${(props) => (props.roomsHidden ? '80%' : '50%')};
  margin: 0 20px;
  /* border: 2px dashed #ad7928; */
`;

export const Background = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  padding: 20px;
  pointer-events: none;
`;

export const Description = styled.p`
  display: none;
  max-width: 60%;
  margin-top: 0;
  font-size: 16px;
  font-family: ${fonts.poppins};

  @media (min-width: 992px) {
    display: block;
  }
`;

export const AreaMarker = styled.button<{ currentArea: boolean }>`
  position: absolute;
  padding: 10px 10px 10px 45px;
  font-family: ${fonts.argestaDisplay};
  font-size: 18px;
  text-transform: uppercase;
  background-image: ${(props) =>
    props.currentArea
      ? "url('/img/ui/marker-pin.svg')"
      : "url('/img/ui/circle-blue.svg')"};
  background-position: 15px center;
  background-repeat: no-repeat;
  background-color: ${colours.white};
  border: 2px solid ${colours.themePrimary};
  border-radius: 30px;
  transform: translate(-50%, -50%);
  transition: background 0.3s ease;

  &:hover {
    background-color: ${colours.grey};
    cursor: pointer;
  }

  @media (min-width: 992px) {
    padding: 15px 15px 15px 45px;
  }
`;

export const Marker = styled(Link)<{ disabled?: boolean }>`
  position: absolute;
  width: 15px;
  height: 15px;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 0.5s linear;
  pointer-events: ${(props) => (props.disabled ? 'none' : '')};

  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
  }

  &.pulse {
    transform: translate(-50%, -50%) scale(1.3);
  }

  @media (min-width: 992px) {
    width: 25px;
    height: 25px;
  }

  img {
    width: 100%;
  }
`;

export const RoomList = styled.div`
  display: none;
  width: 100%;
  max-width: 1400px;
  margin: 0 50px;

  @media (min-width: 992px) {
    display: block;
  }

  .title {
    position: relative;
    margin: 30px 0;
    font-family: 'Argesta Display';
    font-size: 30px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      width: 40%;
      height: 2px;
      background-color: black;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  .label-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 100px;
  }
`;

export const RoomLabel = styled(Link)<{
  disabled?: boolean;
  $isCurrentRoom?: boolean;
}>`
  display: flex;
  position: relative;
  width: 30%;
  margin: 5px 0;
  font-family: 'Argesta Display';
  font-size: 20px;
  text-decoration: none;
  font-weight: ${(props) => (props.$isCurrentRoom ? 600 : 400)};
  text-align: left;
  text-transform: uppercase;
  color: ${(props) =>
    props.$isCurrentRoom || !props.disabled ? colours.black : colours.midGrey};
  pointer-events: ${(props) => (props.disabled ? 'none' : '')};

  &:hover {
    color: ${colours.black};
    text-decoration: underline;
    cursor: pointer;
  }

  span {
    margin-right: 10px;
  }

  .you-are-here-icon {
    position: absolute;
    top: 1px;
    left: -20px;
    height: 20px;
  }
`;

export const MapChangeButton = styled.button`
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding-right: 20px;
  font-size: 18px;
  font-family: ${fonts.argestaDisplay};
  color: ${colours.themePrimary};
  text-transform: uppercase;
  text-decoration: underline;
  background-image: url('/img/ui/icons/icon-arrow-grand.svg');
  background-position: right center;
  background-repeat: no-repeat;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 992px) {
    padding-right: 40px;
    bottom: 35px;
    right: 35px;
    font-size: 30px;
  }
`;

export const DownloadButton = styled.a`
  margin: 15px 0;
  padding: 15px 30px;
  color: ${colours.black};
  font-family: 'Argesta Display';
  font-size: 30px;
  background-color: ${colours.grey};
  text-transform: uppercase;
  text-decoration: none;
  transition: background 0.3s ease;
  ${notchedEdges('12px')}

  &:hover {
    background-color: ${colours.midGrey};
    cursor: pointer;
  }
`;
