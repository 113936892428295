import styled from 'styled-components';

export const ButtonContainer = styled.div<{
  textColour: string;
  backgroundColour: string;
  borderColour: string;
  hoverColour: string;
}>`
  display: inline-block;
  width: 100%;
  margin-top: 15px;
  padding: 10px 30px;
  background-color: ${(props) => props.backgroundColour};
  color: ${(props) => props.textColour};
  font-size: 20px;
  font-weight: 600;
  border: 2px solid ${(props) => props.borderColour};
  transition: 0.5s ease background-color;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.hoverColour};
  }
`;
