import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import store from '@store/store';
import { GlobalStyle, theme } from '@styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

// To enable Sentry Error Logging add the REACT_APP_SENTRY_PUBLIC_KEY to .env
// https://docs.sentry.io/platforms/javascript/guides/react/
if (process.env.REACT_APP_SENTRY_PUBLIC_KEY) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_PUBLIC_KEY,
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <App />
        </ThemeProvider>
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
