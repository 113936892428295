import { colours, notchedEdges } from '@styles';
import styled from 'styled-components';

export const UnsupportedWarningContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${colours.darkGrey};
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 800px;
  padding: 50px 20px;
  background-color: ${colours.white};
  ${notchedEdges('20px')}
`;

export const Image = styled.img``;

export const Text = styled.p`
  text-align: center;

  @media (min-width: 768px) {
    font-size: 20px;
    max-width: 600px;
  }
`;
