import styled from 'styled-components';

export const PhotosphereContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  z-index: 2;

  .psv-navbar {
    display: none;
  }
`;

export const LookPointDebug = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  padding: 10px;
  background-color: yellow;
  color: black;
  z-index: 100;

  &::after {
    content: '';
    position: absolute;
    top: calc(50vh - 2.5px);
    right: calc(50vw - 2.5px);
    width: 5px;
    height: 5px;
    background-color: yellow;
    border-radius: 50%;
  }
`;
