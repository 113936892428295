import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colours, device, fonts, notchedEdges } from '@styles';

export const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: ${colours.darkGrey};
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 70%;
  padding: 100px;
  text-align: center;
  background: ${colours.white};
  ${notchedEdges('40px')};
`;

export const IntroText = styled.div`
  p {
    font-family: ${fonts.poppins};
    font-size: 22px;

    &.bold {
      font-weight: 600;
    }
  }
`;

export const FullScreenWarning = styled.div`
  p {
    font-family: ${fonts.poppins};
    font-size: 20px;

    &.bold {
      font-weight: 600;
    }
  }
`;

export const Title = styled.h1`
  font-size: 40px;
  text-transform: uppercase;
  font-family: ${fonts.argestaHeadline};
  color: ${colours.themePrimary};
`;

export const StartButton = styled(Link)`
  /* width: 50%; */
  margin: 30px auto 0;
  padding: 10px 90px 10px 60px;
  background-color: white;
  background-image: url('./img/ui/arrow.svg');
  background-position: right;
  background-repeat: no-repeat;
  background-size: 60px;
  border: 1px solid #0e3453;
  font-family: 'Argesta Display';
  text-transform: uppercase;
  text-decoration: none;
  color: #0e3453;
  font-size: 32px;
  font-weight: bold;
  transition: background 0.5s ease;

  &:hover {
    cursor: pointer;
    background-color: #eee;
  }

  @media only screen and (max-width: 1024px) {
    margin-top: 0;
    font-size: 20px;
  }
`;
