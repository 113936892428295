import { colours } from '@styles';
import styled from 'styled-components';

export const FullscreenVideoEl = styled.video<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  transition: 1s opacity linear;
  pointer-events: ${(props) => (props.visible ? 'all' : 'none')};
  z-index: 101;
`;

export const PlayButton = styled.button`
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  background-color: ${colours.white};
  border-radius: 50%;
  background-image: url('/img/ui/arrow.svg');
  background-size: 120%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  z-index: 102;

  &:hover {
    background-color: ${colours.grey};
    cursor: pointer;
  }

  &.show {
    display: block;
  }
`;
