import { DefaultTheme } from 'styled-components';

/**
 * Values set here will be available as props in styled components
 * To update the DefaultTheme interface edit styled.d.ts
 *
 * @example
 * font-family: ${(props) => props.theme.baseFont};
 */
export const theme: DefaultTheme = {
  baseFont: `Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif`,
};
