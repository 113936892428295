import store from '@store/store';
import { selectTourID } from '@store/tour';

export const updateCurrentTour = (tourIDFromUrl: string): void => {
  const state = store.getState();
  const tourID = selectTourID(state);
  if (tourIDFromUrl !== tourID) {
    store.dispatch({
      type: 'tour/setTourID',
      payload: tourIDFromUrl,
    });
  }
};
