import Header from '@layouts/Header';
import { colours } from '@styles';
import React from 'react';
import {
  OffboardingContainer,
  Content,
  Title,
  Text,
  ButtonLink,
  InternalLink,
} from './Offboarding.styled';

export const Offboarding = () => {
  return (
    <OffboardingContainer>
      <Header
        backgroundColour={colours.midGrey}
        decorativeBottomBorder={true}
        largetitle="Wentworth Stories"
      />

      <Content>
        <Title>Thanks for visiting!</Title>
        <Text>
          Have a look on our What&apos;s On page to see all of our upcoming
          events, physical tours and virtual experiences.
        </Text>
        <ButtonLink href="https://wentworthwoodhouse.org.uk/whats-on/">
          Explore What&apos;s On
        </ButtonLink>
        <Text>
          Or, if you’d like to go back and re-experience the tour, please click
          the link below to go back to your tickets. Each tour is available for
          30 days after activation.
        </Text>
        <InternalLink to="/your-tickets/">
          Back to Wentworth Stories
        </InternalLink>
      </Content>
    </OffboardingContainer>
  );
};
