import styled from 'styled-components';

export const PreloaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #393939;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 70%;
  background: white;

  @media only screen and (max-width: 1024px) {
    width: 80%;
    height: 90%;
  }
`;

export const LoadingSpinner = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  margin: 60px auto;
  font-size: 10px;
  text-indent: -9999%;
  border: 20px solid rgba(14, 52, 83, 0.2);
  border-left: 20px solid #0e3453;
  transform: translateZ(0);
  animation: load 1.1s infinite linear;
  border-radius: 50%;

  &::after {
    border-radius: 50%;
    width: 150px;
    height: 150px;
  }

  @media only screen and (max-width: 992px) {
    width: 50px;
    height: 50px;
    margin: 20px auto;
  }

  @keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Progress = styled.h1`
  margin: 0 0 15px;
  font-family: 'Argesta Text';
  font-size: 50px;
  text-transform: uppercase;
  text-align: center;
  color: #0e3453;
`;

export const Text = styled.h2<{ small?: boolean }>`
  margin: 0 0 15px;
  font-family: 'Argesta Text';
  font-size: ${(props) => (props.small ? '20px' : '40px')};
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
  color: #0e3453;
`;
