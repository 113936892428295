import styled from 'styled-components';

export const RoomContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease;
    z-index: 100;
  }

  &.cover-black {
    &::after {
      opacity: 1;
    }
  }
`;

export const FlickerLightCover = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  opacity: 0.4;
  pointer-events: none;
  z-index: 50;

  &.animate {
    display: block;
    animation: flicker 2s forwards;
  }

  @keyframes flicker {
    0% {
      opacity: 0.4;
    }
    29% {
      opacity: 0.4;
    }
    30% {
      opacity: 0;
    }
    35% {
      opacity: 0.4;
    }
    49% {
      opacity: 0.4;
    }
    50% {
      opacity: 0;
    }
    55% {
      opacity: 0.4;
    }
    70% {
      opacity: 0.4;
    }
    100% {
      opacity: 0;
    }
  }
`;
