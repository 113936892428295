import { RoomInformation, selectModalInfo, setModalInfo } from '@store/rooms';
import { useAppDispatch } from '@store/store';
import { selectLoadedAssets, selectSlowConnection } from '@store/tour';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  ModalWrapper,
  ModalClose,
  ModalContainer,
  ModalText,
  ModalTitle,
  ModalContent,
  ModalImage,
} from './Modal.styled';

interface Props {
  room: RoomInformation;
  design: string;
}

export const Modal = ({ room, design }: Props) => {
  const dispatch = useAppDispatch();
  const modalInfo = useSelector(selectModalInfo);
  const loadedAssets = useSelector(selectLoadedAssets);
  const slowConnection = useSelector(selectSlowConnection);

  const loadedModalAsset = loadedAssets.filter(
    (asset) => asset.id === modalInfo.image
  )[0];

  const closeModal = () => {
    const modalWrapper = document.getElementById('modal-wrapper');
    modalWrapper?.classList.add('hide');
    window.dispatchEvent(new Event('next-event'));
    setTimeout(() => {
      // delayed so that it doesn't dispppear during fade out of modal
      dispatch(setModalInfo({ title: '', text: '', image: '' }));
    }, 500);
  };
  return (
    <ModalWrapper id="modal-wrapper" className="hide">
      <ModalContainer design={design}>
        <ModalClose
          id="modal-close"
          onClick={() => {
            closeModal();
          }}
        />
        {modalInfo.title && (
          <ModalTitle>
            <h2>{room.title}</h2>
            <h1>{modalInfo.title}</h1>
          </ModalTitle>
        )}
        <ModalContent dual={!!modalInfo.image && !!modalInfo.text}>
          {modalInfo.text && (
            <ModalText
              dual={!!modalInfo.image && !!modalInfo.text}
              dangerouslySetInnerHTML={{ __html: modalInfo.text }}
            />
          )}
          {modalInfo.image && (
            <ModalImage
              src={
                loadedModalAsset
                  ? loadedModalAsset.url
                  : `/img/tour-content/${
                      slowConnection ? 'low-res' : 'hi-res'
                    }/${modalInfo.image}`
              }
              alt=""
              dual={!!modalInfo.image && !!modalInfo.text}
            />
          )}
        </ModalContent>
      </ModalContainer>
    </ModalWrapper>
  );
};
