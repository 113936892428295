import { colours, notchedEdges } from '@styles';
import styled from 'styled-components';

export const AppContainer = styled.div``;

export const PortraitWarning = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: ${colours.lightGrey};
  background-image: url('/img/onboarding/background-pattern.svg');

  @media only screen and (orientation: portrait) {
    display: flex;
  }

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 80%;
    padding: 10px;
    background: ${colours.grey};
    ${notchedEdges('20px')};
  }

  h1 {
    text-align: center;
    color: ${colours.themePrimary};
  }
`;
