import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Audio, RoomInformation } from './rooms';
import { RootState } from './rootReducer';
import { TicketDetails } from './types';

export interface TourListItem {
  name: string;
  title: string;
}

type DeviceType =
  | 'desktop'
  | 'mobile'
  | 'vr'
  | 'cardboard'
  | 'cardboard-trigger';

interface RoomTriggeredSequences {
  [key: string]: string[];
}

export interface Asset {
  id: string;
  path: string;
  type: string;
  filename?: string;
  castShadow?: boolean;
}
interface LoadedAsset {
  id: string;
  url: string;
}

interface MapRoom {
  id: string;
  name: string;
  position: [number, number];
}

interface Area {
  title: string;
  background: string;
  rooms: Array<MapRoom>;
  description?: string;
  overviewMarkerPosition?: {
    x: number;
    y: number;
  };
}
interface Map {
  areas: Array<Area>;
  showOverview: boolean;
  hideRoomSelect?: boolean;
  hideMarkerLabels?: boolean;
  onlyShowVisitedRooms?: boolean;
  showVisualMap?: boolean;
  label?: string;
  downloadFile?: string;
}

export interface TourAccessInformation {
  id: string;
  type: 'open' | 'ticketed' | 'password';
  password?: string;
}

interface TourState {
  id: string;
  name: string;
  slug: string;
  menuOptions: string[];
  onboardingText: string;
  startRoom: string;
  uiStyle: string;
  showLookPoint: boolean;
  globalAudio: Audio;
  rooms: RoomInformation[];
  currentRoom: RoomInformation | null;
  models: any[];
  verified: boolean | null;
  deviceType: DeviceType;
  loadedAssets: Array<LoadedAsset>;
  assetList: Array<Asset>;
  assetsLoaded: boolean;
  map: Map;
  previousRoom: string;
  slowConnection: boolean | null;
  backgroundAudioMuted: boolean;
  triggeredSequences: RoomTriggeredSequences;
  showHelpOverlay: boolean;
  tickets: TicketDetails[];
  showCaptions: boolean;
  visitedRooms: string[];
  toursAccessInformation: TourAccessInformation[];
}

const initialState: TourState = {
  id: '',
  name: '',
  slug: '',
  startRoom: '',
  globalAudio: {
    filename: '',
    initialVolume: 0,
  },
  onboardingText: '',
  menuOptions: [],
  showLookPoint: false,
  uiStyle: '',
  rooms: [],
  currentRoom: null,
  models: [],
  verified: null,
  deviceType: 'desktop',
  loadedAssets: [],
  assetList: [],
  assetsLoaded: false,
  map: {
    showOverview: false,
    areas: [],
  },
  previousRoom: '',
  slowConnection: null,
  backgroundAudioMuted: false,
  triggeredSequences: {},
  showHelpOverlay: false,
  tickets: [],
  showCaptions: false,
  visitedRooms: [],
  toursAccessInformation: [],
};

export const tourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    setTourID: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setTourName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setTourSlug: (state, action: PayloadAction<string>) => {
      state.slug = action.payload;
    },
    setOnboardingText: (state, action: PayloadAction<string>) => {
      state.onboardingText = action.payload;
    },
    setShowLookPoint: (state, action: PayloadAction<boolean>) => {
      state.showLookPoint = action.payload;
    },
    setTourStartRoom: (state, action: PayloadAction<string>) => {
      state.startRoom = action.payload;
    },
    setMenuOptions: (state, action: PayloadAction<string[]>) => {
      state.menuOptions = action.payload;
    },
    setRooms: (state, action: PayloadAction<RoomInformation[]>) => {
      state.rooms = action.payload;
    },
    setCurrentRoom: (state, action: PayloadAction<RoomInformation | null>) => {
      state.currentRoom = action.payload;
    },
    setModels: (state, action: PayloadAction<any>) => {
      state.models = action.payload;
    },
    setVerified: (state, action: PayloadAction<boolean>) => {
      state.verified = action.payload;
    },
    setDeviceType: (state, action: PayloadAction<DeviceType>) => {
      state.deviceType = action.payload;
    },
    setMapInformation: (state, action: PayloadAction<Map>) => {
      state.map = action.payload;
    },
    setAssetList: (state, action: PayloadAction<Array<Asset>>) => {
      state.assetList = action.payload;
    },
    addLoadedAsset: (state, action: PayloadAction<LoadedAsset>) => {
      const { loadedAssets } = state;
      loadedAssets.push(action.payload);
      state.loadedAssets = loadedAssets;
    },
    setGlobalAudio: (state, action: PayloadAction<Audio>) => {
      state.globalAudio = action.payload;
    },
    setUiStyle: (state, action: PayloadAction<string>) => {
      state.uiStyle = action.payload;
    },
    setPreviousRoom: (state, action: PayloadAction<string>) => {
      state.previousRoom = action.payload;
    },
    setAssetsLoaded: (state, action: PayloadAction<boolean>) => {
      state.assetsLoaded = action.payload;
    },
    setSlowConnection: (state, action: PayloadAction<boolean>) => {
      state.slowConnection = action.payload;
    },
    setAmbientAudioMuted: (state, action: PayloadAction<boolean>) => {
      state.backgroundAudioMuted = action.payload;
    },
    addTriggeredSequence: (
      state,
      action: PayloadAction<RoomTriggeredSequences>
    ) => {
      state.triggeredSequences = action.payload;
    },
    setHelpOverlayState: (state, action: PayloadAction<boolean>) => {
      state.showHelpOverlay = action.payload;
    },
    setTickets: (state, action: PayloadAction<TicketDetails[]>) => {
      state.tickets = action.payload;
    },
    setShowCaptions: (state, action: PayloadAction<boolean>) => {
      state.showCaptions = action.payload;
    },
    setToursAccessInformation: (
      state,
      action: PayloadAction<TourAccessInformation[]>
    ) => {
      state.toursAccessInformation = action.payload;
    },
    addVisitedRoom: (state, action: PayloadAction<string>) => {
      const visitedRooms = state.visitedRooms;
      visitedRooms.push(action.payload);
      state.visitedRooms = visitedRooms;
    },
  },
});

export const {
  setTourID,
  setTourName,
  setTourSlug,
  setTourStartRoom,
  setRooms,
  setCurrentRoom,
  setModels,
  setVerified,
  setDeviceType,
  setAssetList,
  addLoadedAsset,
  setMapInformation,
  setGlobalAudio,
  setUiStyle,
  setPreviousRoom,
  setAssetsLoaded,
  setSlowConnection,
  setAmbientAudioMuted,
  setShowLookPoint,
  addTriggeredSequence,
  setHelpOverlayState,
  setMenuOptions,
  setOnboardingText,
  setTickets,
  setShowCaptions,
  addVisitedRoom,
  setToursAccessInformation,
} = tourSlice.actions;

export const selectTourID = (state: RootState): string => state.tour.id;

export const selectTourName = (state: RootState): string => state.tour.name;

export const selectTourSlug = (state: RootState): string => state.tour.slug;

export const selectTourStartRoom = (state: RootState): string =>
  state.tour.startRoom;

export const selectRooms = (state: RootState): RoomInformation[] =>
  state.tour.rooms;

export const selectModels = (state: RootState): any => state.tour.models;

export const selectVerified = (state: RootState): boolean | null =>
  state.tour.verified;

export const selectCurrentRoom = (state: RootState): RoomInformation | null =>
  state.tour.currentRoom;

export const selectDeviceType = (state: RootState): DeviceType =>
  state.tour.deviceType;

export const selectAssetList = (state: RootState): Asset[] =>
  state.tour.assetList;

export const selectLoadedAssets = (state: RootState): LoadedAsset[] =>
  state.tour.loadedAssets;

export const selectMapInformation = (state: RootState): Map => state.tour.map;

export const selectGlobalAudio = (state: RootState): Audio =>
  state.tour.globalAudio;

export const selectUiStyle = (state: RootState): string => state.tour.uiStyle;

export const selectPreviousRoom = (state: RootState): string =>
  state.tour.previousRoom;

export const selectAssetsLoaded = (state: RootState): boolean =>
  state.tour.assetsLoaded;

export const selectSlowConnection = (state: RootState): boolean | null =>
  state.tour.slowConnection;

export const selectBackgroundAudioMuted = (state: RootState): boolean =>
  state.tour.backgroundAudioMuted;

export const selectShowLookPoint = (state: RootState): boolean =>
  state.tour.showLookPoint;

export const selectHelpOverlayState = (state: RootState): boolean =>
  state.tour.showHelpOverlay;

export const selectTriggeredSequences = (
  state: RootState
): RoomTriggeredSequences => state.tour.triggeredSequences;

export const selectMenuOptions = (state: RootState): string[] =>
  state.tour.menuOptions;

export const selectOnboardingText = (state: RootState): string =>
  state.tour.onboardingText;

export const selectTickets = (state: RootState): TicketDetails[] =>
  state.tour.tickets;

export const selectShowCaptions = (state: RootState): boolean =>
  state.tour.showCaptions;

export const selectVisistedRooms = (state: RootState): string[] =>
  state.tour.visitedRooms;

export const selectToursAccessInformation = (
  state: RootState
): TourAccessInformation[] => state.tour.toursAccessInformation;

export default tourSlice.reducer;
