import store from '@store/store';
import { eventHandler } from '@functions/eventHandler';
import { History } from 'history';
import { RoomInformation } from '@store/rooms';
import { selectTriggeredSequences } from '@store/tour';

export const sequenceHandler = (
  sequenceID: string,
  currentRoom: RoomInformation | null = null,
  history?: History<unknown>,
  marker?: HTMLDivElement
): void => {
  if (!currentRoom) return;
  const { scripting, events: eventList } = currentRoom;
  const { sequences } = scripting;
  if (sequences === null) return;
  const chosenSequence = sequences.filter(
    (sequence) => sequence.id === sequenceID
  )[0];
  console.log({ sequenceID });
  const chosenEvents = chosenSequence.events;
  let eventIndex = 0;
  const triggerNextEvent = () => {
    eventIndex++;
    if (chosenEvents[eventIndex])
      eventHandler(chosenEvents[eventIndex], eventList, history);
    else {
      window.removeEventListener('next-event', triggerNextEvent);

      // Store that sequence has been triggered
      const state = store.getState();
      const triggeredSequences = { ...selectTriggeredSequences(state) };

      if (triggeredSequences[currentRoom.id]) {
        const roomTriggeredSequences = [...triggeredSequences[currentRoom.id]]; // make array extensible
        // console.log(roomTriggeredSequences);
        if (!roomTriggeredSequences.includes(sequenceID)) {
          roomTriggeredSequences.push(sequenceID);
          triggeredSequences[currentRoom.id] = roomTriggeredSequences;
        }
      } else {
        triggeredSequences[currentRoom.id] = [sequenceID];
      }
      store.dispatch({
        type: 'tour/addTriggeredSequence',
        payload: triggeredSequences,
      });
      if (marker) {
        marker.classList.add('opened');
      }
    }
  };
  window.addEventListener('next-event', triggerNextEvent);
  eventHandler(chosenEvents[eventIndex], eventList, history);
  // store.dispatch({
  //   type: 'tour/addTriggeredSequence',
  //   payload: triggeredSequences,
  // });
};
