import React from 'react';
import { FlickerLightCover, RoomContainer } from './Room.styled';

import { Redirect, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga';

import {
  addVisitedRoom,
  selectHelpOverlayState,
  selectRooms,
  selectTriggeredSequences,
} from '@store/tour';
import { useAppDispatch } from '@store/store';

import UI from '@components/UI';
import Photosphere from '@components/Photosphere';
import FullscreenVideo from '@components/FullscreenVideo';

import { setCurrentRoom } from '@store/tour';
import { sequenceHandler } from '@functions/sequenceHandler';
import HelpOverlay from '@components/UIElements/HelpOverlay';

export const Room = () => {
  const dispatch = useAppDispatch();
  const { room: roomID } = useParams<{
    room: string;
  }>();
  const [roomData, setRoomData] = React.useState<any>();
  const [redirect, setRedirect] = React.useState(false);

  const rooms = useSelector(selectRooms);
  const showHelpOverlay = useSelector(selectHelpOverlayState);
  const triggeredSequences = useSelector(selectTriggeredSequences);

  React.useEffect(() => {
    if (rooms.length) {
      const currentRoomData = rooms.filter((room) => room.id === roomID)[0];
      if (currentRoomData) {
        setRoomData(currentRoomData);
        dispatch(setCurrentRoom(currentRoomData));
        dispatch(addVisitedRoom(roomID));

        const { sequences } = currentRoomData.scripting;
        if (sequences) {
          const autoplayingSequences = sequences.filter(
            (sequence) => sequence.autoplay
          );
          const roomTriggeredSequences =
            triggeredSequences[currentRoomData.id] || [];
          autoplayingSequences.forEach((autoplaySeq) => {
            console.log(autoplaySeq);
            if (
              !roomTriggeredSequences.includes(autoplaySeq.id) &&
              !autoplaySeq.repeat
            ) {
              sequenceHandler(autoplaySeq.id, currentRoomData);
            }
          });
        }
      } else setRedirect(true);
    }
  }, [rooms.length]);

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  });

  return (
    <RoomContainer id="room" className="cover-black">
      <FlickerLightCover id="flicker" />
      {redirect && <Redirect to="/404" />}
      {roomData && (
        <React.Fragment>
          {showHelpOverlay && <HelpOverlay />}
          <FullscreenVideo />
          <UI room={roomData} />
          <Photosphere room={roomData} />
        </React.Fragment>
      )}
    </RoomContainer>
  );
};
