import {
  selectToursAccessInformation,
  TourAccessInformation,
} from '@store/tour';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import {
  ErrorMessage,
  Input,
  PasswordVerificationContainer,
  Submit,
} from './PasswordVerification.styled';

export const PasswordVerification = ({}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const passwordInputRef = React.useRef<HTMLInputElement>(null);

  const history = useHistory();

  const [passwordTours, _setPasswordTours] = useState<TourAccessInformation[]>(
    []
  );
  const passwordToursRef = React.useRef(passwordTours);
  const setPasswordTours = (tours) => {
    passwordToursRef.current = tours;
    _setPasswordTours(tours);
  };
  const toursAccessInformation = useSelector(selectToursAccessInformation);

  const checkPassword = () => {
    const input = passwordInputRef.current;
    if (input instanceof HTMLInputElement) {
      const password = input.value;
      const chosenTour = passwordToursRef.current.filter(
        (tour) => tour.password === password
      )[0];
      if (chosenTour) {
        Cookies.remove(chosenTour.id);
        Cookies.set(chosenTour.id, 'valid', {
          expires: 10,
        });
        history.push(`/${chosenTour.id}`);
      } else {
        if (containerRef.current) {
          containerRef.current.classList.add('error');
        }
      }
    }
  };

  useEffect(() => {
    const input = passwordInputRef.current;
    if (input) {
      input.addEventListener('keypress', (e) => {
        if (e.key === 'Enter') checkPassword();
      });
    }
  }, []);

  useEffect(() => {
    if (toursAccessInformation.length && !passwordTours.length) {
      setPasswordTours(
        toursAccessInformation.filter((tour) => tour.type === 'password')
      );
    }
  }, [toursAccessInformation, passwordTours]);

  return (
    <PasswordVerificationContainer ref={containerRef}>
      <Input
        ref={passwordInputRef}
        type="text"
        placeholder="Enter your code"
        name="password"
      />
      <Submit onClick={checkPassword} />
      <ErrorMessage>
        Sorry, we couldn&apos;t match that code to a tour.
      </ErrorMessage>
    </PasswordVerificationContainer>
  );
};
