import styled from 'styled-components';

export const VersionContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 8px;
  background-color: white;
  font-weight: bold;
  z-index: 3;
`;
