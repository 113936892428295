import { useAppDispatch } from '@store/store';
import {
  selectCurrentRoom,
  selectTourSlug,
  setHelpOverlayState,
} from '@store/tour';
import { SVG } from '@svgdotjs/svg.js';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  CloseButton,
  HelpOverlayContainer,
  HighlightOverlay,
  NextButton,
  TextContainer,
} from './HelpOverlay.styled';

import { sequenceHandler } from '@functions/sequenceHandler';
import { EqualStencilFunc } from 'three';

export const HelpOverlay = () => {
  const dispatch = useAppDispatch();
  const tourSlug = useSelector(selectTourSlug);
  const room = useSelector(selectCurrentRoom);

  const [helpItemIndex, setHelpItemIndex] = React.useState(0);

  const closeOverlay = () => {
    dispatch(setHelpOverlayState(false));

    if (tourSlug === 'grand' && room?.id === 'pillared-hall') {
      sequenceHandler('intro-dee-video', room);
    } else if (tourSlug === 'demo' && room?.id === 'G.2.11-pillared-hall') {
      sequenceHandler('intro-dee-video', room);
    }
  };

  const handleHelpStep = (step) => {
    const maskEllipse = SVG('#mask-ellipse');
    switch (step) {
      case 0:
        step0(maskEllipse);
        break;
      case 1:
        step1(maskEllipse);
        break;
      case 2:
        step2(maskEllipse);
        break;
      case 3:
        step3(maskEllipse);
        break;
      case 4:
        step4(maskEllipse);
        break;
      case 5:
        step5(maskEllipse);
        break;
      default:
        break;
    }
  };

  const step0 = (maskEllipse) => {
    // Make invisible
    // maskEllipse
    //   .animate({ ease: '<>', duration: '0.3s' })
    //   .attr({ fill: '#fff' });
    // Move mask
    maskEllipse.attr({
      cx: 0,
      cy: 0,
      rx: 0,
      ry: 0,
    });
  };

  const step1 = (maskEllipse) => {
    // Move mask
    maskEllipse.attr({
      cx: 50,
      cy: 50,
      rx: 65,
      ry: 55,
    });

    // Make visible
    // maskEllipse
    //   .animate({ ease: '<>', duration: '0.3s' })
    //   .attr({ fill: '#000' });
  };

  const step2 = (maskEllipse) => {
    maskEllipse.attr({
      cx: 120,
      cy: '90%',
      rx: 150,
      ry: 50,
    });
  };

  const step3 = (maskEllipse) => {
    maskEllipse.attr({
      cx: '50%',
      cy: '90%',
      rx: '15%',
      ry: 75,
    });
  };

  const step4 = (maskEllipse) => {
    maskEllipse.attr({
      cx: '90%',
      cy: '90%',
      rx: 100,
      ry: 50,
    });
  };

  const step5 = (maskEllipse) => {
    maskEllipse.attr({
      cx: 0,
      cy: 0,
      rx: 0,
      ry: 0,
    });
  };

  const nextHelpItem = () => {
    const helpItems = document.querySelectorAll('#help-overlay > div.item');
    const newhelpItemIndex = helpItemIndex + 1;
    if (newhelpItemIndex >= helpItems.length) closeOverlay();
    else {
      helpItems[helpItemIndex].classList.remove('show');
      helpItems[newhelpItemIndex].classList.add('show');
      setHelpItemIndex(newhelpItemIndex);
    }
  };

  React.useEffect(() => {
    const helpItems = document.querySelectorAll('#help-overlay > div.item');
    helpItems[helpItemIndex].classList.add('show');
  }, []);

  React.useEffect(() => {
    handleHelpStep(helpItemIndex);
  }, [helpItemIndex]);

  return (
    <HelpOverlayContainer id="help-overlay">
      <HighlightOverlay>
        <svg width="100%" height="100%">
          <defs>
            <filter
              height="200%"
              width="200%"
              y="-50%"
              x="-50%"
              id="gaussian_blur"
            >
              <feGaussianBlur stdDeviation="10" in="SourceGraphic" />
            </filter>
            <mask id="mask-svg">
              <rect
                fill="#fff"
                id="canvas-background"
                width="101%"
                height="101%"
                y="-1%"
                x="-1%"
              />
              <ellipse
                filter="url(#gaussian_blur)"
                ry="100"
                rx="100"
                id="mask-ellipse"
                cx="0"
                cy="0"
                fill="#000"
              />
            </mask>
          </defs>
          <rect
            width="100%"
            height="100%"
            style={{ fill: '#000', opacity: 0.7 }}
            mask="url(#mask-svg)"
          ></rect>
        </svg>
      </HighlightOverlay>
      <TextContainer
        className="item"
        style={{
          width: '450px',
        }}
      >
        <h1>Quick Guide</h1>
        <p>
          Wentworth Woodhouse Stories is simple to operate, as you explore the
          house look for the following markers:
        </p>

        <ul>
          <li>
            <span
              className={`icon icon--${tourSlug}`}
              style={{
                backgroundImage: `url("/img/ui/icons/icon-info-inverted.png")`,
              }}
            ></span>
            <p>Information markers to give you insight into the house.</p>
          </li>
          <li>
            <span
              className={`icon icon--${tourSlug}`}
              style={{
                backgroundImage: `url("/img/ui/button-proceed-arrow.svg")`,
              }}
            ></span>
            <p>
              Arrows allow you to move to different places in the same room.
            </p>
          </li>
          <li>
            <span
              className={`icon icon--${tourSlug}`}
              style={{
                backgroundImage: `url("/img/ui/button-proceed-normal.svg")`,
              }}
            ></span>
            <p>Doors move you to a new room.</p>
          </li>
          <li>
            <span
              className={`icon icon--${tourSlug}`}
              style={{
                backgroundImage: `url("/img/ui/icons/button-time-back.svg")`,
              }}
            ></span>
            <p>Reverts the room to its default state.</p>
          </li>
        </ul>
        <NextButton onClick={nextHelpItem}>Next</NextButton>
      </TextContainer>

      <TextContainer className="item">
        <p>
          Click on the
          <img src="/img/ui/icons/help-icon-menu.svg" alt="" />
          button above to open the menu and see more options.
        </p>

        <NextButton onClick={nextHelpItem}>Next</NextButton>
      </TextContainer>

      <TextContainer className="item">
        <p>
          Click on the
          <img src="/img/ui/icons/help-icon-map.svg" alt="" />
          button below to see all the rooms available to you on this tour.
        </p>

        <NextButton onClick={nextHelpItem}>Next</NextButton>
      </TextContainer>

      <TextContainer className="item">
        <p>
          Click and drag on the screen to move around, or use the
          <img src="/img/ui/icons/help-icon-arrow-left.svg" alt="" /> and
          <img src="/img/ui/icons/help-icon-arrow-right.svg" alt="" /> buttons
          below to look left and right.
        </p>

        <NextButton onClick={nextHelpItem}>Next</NextButton>
      </TextContainer>

      <TextContainer className="item">
        <p>Click here to show and hide the onscreen markers.</p>

        <NextButton onClick={nextHelpItem}>
          {tourSlug === 'grand' ? 'Next' : 'Close'}
        </NextButton>
      </TextContainer>

      {tourSlug === 'grand' && (
        <TextContainer className="item">
          <p>
            The special icons{' '}
            <img src="/img/ui/icons/button-time-back.svg" alt="" /> and{' '}
            <img src="/img/ui/icons/button-picture-change.svg" alt="" /> will
            swap you between different versions of the current room e.g.
            transporting you between the past and the present.
          </p>

          <NextButton onClick={nextHelpItem}>Close</NextButton>
        </TextContainer>
      )}

      <CloseButton onClick={closeOverlay}>
        <span>Close help overlay</span>
      </CloseButton>
      {/*
      <CarouselButton style={{ right: '20px' }} onClick={nextHelpItem} />
      <CarouselButton
        style={{ left: '20px', transform: 'rotate(180deg)' }}
        onClick={prevHelpItem}
      /> */}
    </HelpOverlayContainer>
  );
};
