import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';
import rootReducer from './rootReducer';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = configureStore({
  reducer: rootReducer,
  enhancers: [sentryReduxEnhancer],
});

export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export default store;
